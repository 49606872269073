import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useEditSite = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const { data: { data: { data: selectedSite = {} } = {} } = {} } = useQuery(
    ['siteDetail', id],
    () => api().get(apiEndpoints.singleSite(id)),

    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const { mutate: handleEditSite } = useMutation(
    (payload) => api().patch(apiEndpoints.singleSite(id), payload),
    {
      onSuccess: () => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: 'Site Updated Successfully'
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error?.message || 'Something went wrong, please try after sometime'
          })
        );
      }
    }
  );

  return { handleEditSite, selectedSite };
};
