import React, { useState } from 'react';

import plusFill from '@iconify/icons-eva/plus-fill';
import Icon from '@iconify/react';
import {
  Button,
  Card,
  CircularProgress,
  Container,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Edit, Assignment } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import UserListToolbar from '../../../components/list-toolbar';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchFilter from '../../../components/search-filter';
import SearchNotFound from '../../../components/SearchNotFound';
import { iconStyle } from '../../../constants';
import { useUserList } from './hooks';
import DeleteUser from '../delete-user';
import TableHeader from '../../../components/table-header';

const TABLE_HEAD = [
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'sites', label: 'Sites', alignRight: false, showSort: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

const UserList = () => {
  const {
    user,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSiteName,
    handleSelectAllClick,
    handleRequestSort,
    searchedSite,
    selected,
    users,
    total,
    handleFilterByUserName,
    params,
    handleSiteId,
    Classes,
    refetchUsers,
    usersLoading
  } = useUserList();
  return (
    <Page title="Users | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/users/add-user"
            startIcon={<Icon icon={plusFill} />}
          >
            Add User
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length}>
            <Container style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
              <SearchFilter
                searchedValue={params.username}
                handleSearch={handleFilterByUserName}
                searchPlaceholder='Username'
              />

              <TextField
                style={{ width: '15%' }}
                select
                label="Filter Sites"
                value={params.siteId}
                onChange={handleSiteId}
              >
                <MenuItem value=" ">All Sites</MenuItem>
                {user.sites.map((site, index) => (
                  <MenuItem key={index} value={site}>
                    {site.name}
                  </MenuItem>
                ))}
              </TextField>
            </Container>
          </UserListToolbar>

          {usersLoading ? (
            <CircularProgress
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto'
              }}
            />
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHeader
                      order={params.order}
                      orderBy={params.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      showSelectAll={false}
                    />
                    <TableBody>
                      {users.map((row) => {
                        const { username, phone, email, _id: patientId, sites } = row;
                        const isItemSelected = selected.indexOf(patientId) !== -1;
                        return (
                          <TableRow
                            hover
                            key={patientId}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{username || 'NA'}</TableCell>
                            <TableCell align="left">{handleSiteName(sites) || 'NA'}</TableCell>
                            <TableCell align="left">{email || 'NA'}</TableCell>
                            <TableCell align="left">{phone || 'NA'}</TableCell>

                            <TableCell align="left">
                              <RouterLink to={`/dashboard/users/details/${patientId}`}>
                                <Tooltip title="Details" arrow>
                                  <Assignment style={iconStyle} />
                                </Tooltip>
                              </RouterLink>
                              <RouterLink to={`/dashboard/users/edit/${patientId}`}>
                                <Tooltip title="Edit" arrow>
                                  <Edit style={iconStyle} />
                                </Tooltip>
                              </RouterLink>
                              <DeleteUser
                                id={patientId}
                                refetchUsers={refetchUsers}
                                classes={Classes}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {users.length <= 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={searchedSite} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={params.limit}
                page={Math.floor(params.offset / params.limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
};

export default UserList;
