import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
// material
import { Grid, Tooltip, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { RootStyle } from './style';

export default function ListToolBar({ numSelected, children }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container spacing={2} mt={1}>
          {children}
        </Grid>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}

ListToolBar.propTypes = {
  numSelected: PropTypes.number,
  children: PropTypes.node
};
