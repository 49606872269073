export default {
  getWorklist: '/api/worklist',
  getUsers: '/api/users',
  sites: '/api/sites',
  resetPassword: (id) => `/api/users/reset-password/${id}`,
  singleUser: (id) => `/api/users/${id}`,
  singleSite: (id) => `/api/sites/${id}`,
  singleEntity: (id) => `/api/entities/${id}`,
  entities: '/api/entities',
  roles: '/api/roles',
  singleStudy: (id) => `/api/studies/${id}`,
  markStudyCancel: (studyId, siteId) => `/api/studies/update-status/${studyId}/${siteId}/Cancelled`,
  uploadStudy: '/api/studies/upload-study',
  deleteStudy:(studyId)=>`/api/studies/${studyId}`
};
