import { useState } from 'react';

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

const formField = {
  password: '',
  confirmPassword: ''
};

export const useChangePassword = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormvalues] = useState(formField);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value
    }));
  };

  const { mutate: handleSavePassword } = useMutation(
    (values) => {
      const payload = {
        newPassword: values.password,
        confirmPassword: values.confirmPassword
      };
      return api().patch(apiEndpoints.resetPassword(id), payload);
    },
    {
      onSuccess: (response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.msg
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error.message
          })
        );
      }
    }
  );

  return { modalOpen, handleModal, handleFormUpdate, formValues, handleSavePassword };
};
