import {
  Container,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { useTC } from './hooks';
import styles from './styles.module.scss';

const TermsAndConditions = () => {
  const {
    sections,
    prohibitedUses,
    warranties,
    quickAccess,
    endorsements,
    electronicDocuments,
    executeScroll
  } = useTC();

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        className={styles.drawer}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Typography className={styles.quickAccess}>Quick Access</Typography>
        <List className={styles.list}>
          {quickAccess.map((text, index) => (
            <>
              <ListItem
                onClick={() => executeScroll(index)}
                key={`section${index}`}
                className={styles.quickAccessList}
              >
                <ListItemText primary={text} />
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Drawer>

      <Container maxWidth="xl" className={styles.container}>
        <Grid container>
          <Grid item className={styles.section} ref={sections[0].ref}>
            <Typography variant="h2" className={styles.title}>
              Terms and Conditions (Login Portal) InMed
            </Typography>

            <hr className={styles.horizontalRule} />

            <Typography className={styles.content} align="justify">
              These Terms and Conditions ("Terms and Conditions") form a legal agreement between
              InMed Inc. and you, the individual ("you" and "your") concerning your access to and
              use of the various services offered through the Websites and the Platforms (as defined
              below). Use of any of the Websites or Platforms constitutes your acceptance of these
              Terms and Conditions and the Web and Mobile Privacy Policy.
            </Typography>
            <Typography className={styles.content} align="justify">
              InMed, Inc. on its own behalf and on behalf of its affiliates and/or wholly owned
              subsidiaries including but not limited to{' '}
              <a href="https://inmed.ai/" target="_blank" rel="noreferrer">
                https://inmed.ai/
              </a>
              ; ,
              <a
                href="https://neuroshield.in-medprognostics.com/login"
                target="_blank"
                rel="noreferrer"
              >
                https://neuroshield.in-medprognostics.com/login
              </a>
              ; and. (collectively referred to as "InMed", "we", "us", or "our"), InMed makes
              certain telehealth related information available to you and/or facilitates your
              access.
            </Typography>
            <Typography className={styles.content} align="justify">
              By using the Websites and/or the Platforms, you expressly agree to these Terms and
              Conditions. If you do not agree to these Terms and Conditions, you should immediately
              cease all use of and access to all of the Websites and Platforms. Please print a copy
              of these Terms and Conditions for your records.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[1].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Modifications to these terms and conditions
            </Typography>

            <Typography className={styles.content} align="justify">
              InMed may in its sole discretion, without prior notice to you, revise these Terms and
              Conditions at any time. Should these Terms and Conditions change materially, InMed
              will update the Effective Date noted above and post a notice regarding the updated
              Terms and Conditions on the Websites. The amended Terms and Conditions will also
              appear when the Platforms are accessed by you and you will need to acknowledge your
              agreement to the amended Terms and Conditions prior to being able to continue to use
              the Platforms. If you do not agree to the terms of the amended Terms and Conditions,
              your sole and exclusive remedy is to discontinue your use of the Websites and
              Platforms and you will be deemed to have terminated these Terms and Conditions.
              Amended Terms and Conditions will be effective as of the Effective Date unless
              otherwise stated. By accessing or using the Websites and the Platforms after such
              changes are posted you agree and consent to all such changes.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[2].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Access to the platforms
            </Typography>

            <Typography className={styles.content} align="justify">
              You may access and use the Platforms via remote access connectivity. InMed grants you
              a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to
              use the Platforms in accordance with these Terms and Conditions. In order to use the
              Platforms, you will be asked to register an account and create login information,
              including without limitation, username and passwords. You must safeguard your login
              information that you use to access the Platforms and you must not disclose this
              information to anyone.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[3].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Who is InMed
            </Typography>

            <Typography className={styles.content} align="justify">
              InMed is not a healthcare provider or InMed is not an insurance provider nor are we a
              prescription fulfillment warehouse. InMed's role is limited to making certain
              information available based on its AI algorithm . InMed does not provide a foolproof
              report and its recommendations are not final and conclusive proof of patients
              conditions. You must consult a doctor(registered in the roll of Indian Medical
              Registry) for deciding the course of treatment for the patient. InMed does not engage
              in the practice of medicine or provide any other health services.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[4].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Your representations and warranties
            </Typography>

            <Typography className={styles.content} align="justify">
              You represent and warrant that the information that you provide to us if you are a
              “health care provider” or a “vendor” of Inmed, about your patient, will be current,
              true, accurate, supportable and complete. If you are a health care provider, you
              represent and warrant that you have all necessary licenses, registrations, rights,
              consents and approvals to use or disclose Personal Information (any information that
              may be used to identify an individual, such as the patient’s first and last name, age,
              gender, postal address, e-mail address, telephone number, other contact information,
              certain health information, or other personally identifiable information under an
              applicable law such as the Health Insurance Portability and Accountability Act
              (“HIPAA”) or an equivalent State law governing the use or disclosure of health
              information) and if required Protected Health Information (Personal Information in
              conjunction with information related to physical or mental health, such as medical
              history, family history, medical diagnosis, health background, current health status,
              age, gender, sexual behavior and sexual orientation, demographic information
              (including race, ethnicity, marital status, salary, education, political, religious,
              and trade union information) and information related to the diagnosis and treatment of
              health conditions, over-the-counter and prescription medications, laboratory test
              results, payments for treatment and health insurance information or other personally
              identifiable information under an applicable law such as HIPAA or an equivalent State
              law governing the use or disclosure of Protected Health Information and as defined by
              HIPAA).If you are an individual user, you represent and warrant the following:
              <List>
                {warranties.map((item, idx) => (
                  <ListItem key={`warranties-${idx}`}>
                    <ListItemText primary={<Typography>{item}</Typography>} />
                  </ListItem>
                ))}
              </List>
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[5].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Termination
            </Typography>

            <Typography className={styles.content} align="justify">
              If you violate these Terms and Conditions, your ability to use the Websites and/or
              Platforms will be terminated. InMed may, in its sole discretion, terminate your access
              to the Websites and/or Platforms, or any portion thereof, for any reason whatsoever
              without prior notice. These actions are in addition to any other right or remedy InMed
              may have available at law. Further, InMed shall not be liable to you or any third
              party for any such termination or discontinuance. You may terminate these Terms and
              Conditions by ceasing to access and use the Websites and Platforms. Upon any
              termination of these Terms and Conditions you must immediately cease use of the
              Websites and Platforms. To the extent permitted by applicable law, the disclaimers,
              limitations on liability, termination and your warranties and indemnities shall
              survive any termination of these Terms and Conditions.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[6].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Prohibited Uses
            </Typography>

            <Typography className={styles.content} align="justify">
              In using the Websites and Platforms, you agree not to:
            </Typography>
            <Typography className={styles.content} align="justify">
              Send or otherwise transmit to or through the Websites and Platforms
            </Typography>
            <List>
              {prohibitedUses.map((item, idx) => (
                <ListItem key={`prohibited-uses-${idx}`}>
                  <ListItemIcon>
                    <FiberManualRecord className={styles.listItemIcon} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography>{item}</Typography>} />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item className={styles.section} ref={sections[7].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Intellectual property rights and content
            </Typography>

            <Typography className={styles.content} align="justify">
              InMed is the sole and exclusive owner of the Websites and Platforms, including any and
              all copyright, patent, trademark, trade secret and other ownership and intellectual
              property rights, in and to the Websites and Platforms and any related materials and
              documentation. No title or ownership of the Websites and Platforms or any portion
              thereof is transferred to you hereunder. InMed reserves all rights not expressly
              granted hereunder. You agree not to change or delete any copyright or proprietary
              notice related to materials downloaded from the Websites and/or Platforms.
            </Typography>

            <Typography className={styles.content} align="justify">
              You may provide input, comments or suggestions regarding the Websites and Platforms or
              InMed Services ("Feedback"). You acknowledge and agree that InMed may use any Feedback
              without any obligation to you and you hereby grant InMed a worldwide, perpetual,
              irrevocable, royalty-free, transferable license to reproduce, display, perform,
              distribute, publish, modify, edit or otherwise use such Feedback as InMed may deem
              appropriate, without restriction, for any and all commercial and/or non-commercial
              purposes, in its sole discretion.
            </Typography>
          </Grid>
          <Grid item className={styles.section} ref={sections[8].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              External links
            </Typography>

            <Typography className={styles.content} align="justify">
              The Websites and Platforms may contain links to third-party websites. Linked sites are
              not under the control of InMed, and InMed is not responsible for the content
              align='justify' of any linked site. Links are provided as a convenience only, and a
              link does not imply that InMed endorses, sponsors, or is affiliated with the linked
              site. Your use of third-party websites is at your own risk and subject to the terms
              and conditions of use for such sites; these Terms do not apply to other websites.
              InMed disclaims any and all liability for any information, including but without
              limitation, any medical and health treatment information set forth on linked sites.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[9].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              No endorsements
            </Typography>

            <Typography className={styles.content} align="justify">
              You agree to defend, indemnify and hold InMed, its officers, directors, employees,
              shareholders, affiliates, third-party contractors, agents, licensors and suppliers,
              harmless from and against any claims, actions or demands, losses, liabilities,
              damages, costs, expenses and settlements (including without limitation reasonable
              attorney and accounting fees), resulting from or alleged to result from, directly or
              indirectly, your
              <List>
                {endorsements.map((item, idx) => (
                  <ListItem key={`endorsements-${idx}`}>
                    <ListItemText primary={<Typography>{item}</Typography>} />
                  </ListItem>
                ))}
              </List>
            </Typography>

            <Typography className={styles.content} align="justify" sx={{ fontWeight: 600 }}>
              IN NO EVENT SHALL INMED BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE,
              INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING
              FROM ANY LOSS OF USE, LOSS OF PROFITS, LITIGATION, OR ANY OTHER PECUNIARY LOSS,
              WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY,
              OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE WEBSITES OR PLATFORMS OR
              THE PROVISION OF OR FAILURE TO MAKE AVAILABLE ANY SUCH PRODUCTS, GOODS, OR SERVICES,
              EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Typography>
            <Typography className={styles.content} align="justify">
              InMed shall not be liable for any failure to perform its obligations hereunder where
              the failure results from any cause beyond InMed's reasonable control, including,
              without limitation, mechanical, electronic, or communications failure or degradation.
            </Typography>
            <Typography className={styles.content} align="justify">
              The terms of these Terms and Conditions that limit liability reflect an informed
              voluntary allocation of risk; such allocation represents a material part of these
              Terms and Conditions. You agree that the limitations of liabilities set out in these
              Terms and Conditions are fair and reasonable in the circumstances.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[10].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Disclaimer
            </Typography>

            <Typography className={styles.content} align="justify">
              The Websites and Platforms are provided on an "as is" and "as available" basis and
              without warranties of any kind, either express or implied. To the fullest extent
              permissible pursuant to applicable law, InMed disclaims all representations,
              warranties, and conditions, express or implied, including, but not limited to, implied
              condition or warranties of merchantability and fitness for a particular purpose. InMed
              does not warrant that the Websites and Platforms will be uninterrupted or error-free,
              that defects will be corrected or that the Websites and Platforms or the server that
              makes it available are free of viruses or other harmful components.
            </Typography>

            <Typography className={styles.content} align="justify">
              InMed makes no guarantees, and disclaims any implied warranty or representation about
              its accuracy, relevance, timeliness, completeness, or appropriateness of any content
              align='justify' posted on the Website and Platforms for a particular purpose. InMed
              assumes no liability arising from or relating to the delay, failure, interruption, or
              corruption of any data or other information transmitted in connection with use of the
              Websites and/or Platforms.
            </Typography>

            <Typography className={styles.content} align="justify">
              Applicable law may not allow the exclusion of implied warranties, so the above
              exclusion may not apply to you.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[11].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Children
            </Typography>

            <Typography className={styles.content} align="justify">
              The Websites and Platforms are not intended for children under the age of 13. If you
              are under 13 years of age, please do not use or access the Websites or Platforms at
              any time or in any manner. By using the Websites or Platforms, you affirm that you are
              over the age of 13. InMed does not seek through the Websites or Platforms to gather
              personal information from or about persons under the age of 13 without the consent of
              a parent or guardian.
            </Typography>
          </Grid>
          <Grid item className={styles.section} ref={sections[12].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Choice of law and jurisdiction
            </Typography>

            <Typography className={styles.content} align="justify">
              These Terms shall be governed and construed under the laws of Maharashtra, India,
              without regard to its conflict of law provisions.
            </Typography>
            <Typography className={styles.content} align="justify">
              Our failure to enforce any right or provision of these Terms will not be considered a
              waiver of those rights. If any provision of these Terms is held to be invalid or
              unenforceable by a court, these Terms’ residual provisions will remain in effect.
              These Terms constitute the entire agreement between us regarding our Service and
              supersede and replace any prior agreements we might have between us regarding the
              Service.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[13].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Personal information and privacy policy
            </Typography>

            <Typography className={styles.content} align="justify">
              In these Terms and Conditions, "personal information" means any information about an
              identifiable individual, such as your name, email address, mailing address, gender,
              date of birth, any personal or protected health information, or, any data about you
              that you elect to provide electronically through the Websites and Platforms and any
              other information that identifies who you are. Personal Information will be used by
              InMed solely in accordance with these Terms and Conditions, and InMed's Web and Mobile
              Privacy Policy (or as otherwise agreed upon between you and InMed in writing).
            </Typography>
            <Typography className={styles.content} align="justify">
              You agree that InMed has the right to monitor and review your use of the Websites and
              Platforms from time to time, and to use "cookies", "log files" and your "browsing
              data" in accordance with the Cookie Policy.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[14].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Waiver
            </Typography>

            <Typography className={styles.content} align="justify">
              No delay or omission by InMed to exercise any right or power it has under these Terms
              and Conditions or to object to the failure of any covenant of you to be performed in a
              timely and complete manner, shall impair any such right or power or be construed as a
              waiver of any succeeding breach or any other covenant. Any waivers by InMed must be in
              writing and signed by an authorized representative of InMed.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[15].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Entire agreement
            </Typography>

            <Typography className={styles.content} align="justify">
              These Terms and Conditions constitute the entire agreement between you and InMed as it
              relates to the access to, and use of, the Platform and Websites and the subject matter
              of these Terms and Conditions and supersede all prior or contemporaneous agreements,
              negotiations, representations and proposals, written or oral between InMed and you.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[16].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Electronic documents
            </Typography>

            <Typography className={styles.content} align="justify">
              This electronic document, and all other electronic documents referred to or
              incorporated herein, will be:
              <List>
                {electronicDocuments.map((item, idx) => (
                  <ListItem key={`electronic-documents-${idx}`}>
                    <ListItemText primary={<Typography>{item}</Typography>} />
                  </ListItem>
                ))}
              </List>
              A printed version of these Terms and Conditions and any notice given in electronic
              form shall be admissible in judicial proceedings or administrative proceedings based
              upon or relating to these Terms and Conditions to the same extent and subject to the
              same conditions as other business documents and records originally generated and
              maintained in printed form.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[17].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Assignment
            </Typography>

            <Typography className={styles.content} align="justify">
              These Terms and Conditions are personal to you, and are not assignable, transferable,
              or sub licensable by you except with InMed's prior written consent. InMed may assign,
              transfer, or delegate any of its rights and obligations hereunder without your
              consent.
            </Typography>
          </Grid>

          <Grid item className={styles.section} ref={sections[18].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Language
            </Typography>

            <Typography className={styles.content} align="justify">
              English shall be the language of these Terms and the parties waive any right to use
              and rely upon any other language or translations.
            </Typography>
          </Grid>
          <Grid item className={styles.section} ref={sections[19].ref}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Contact / Notices
            </Typography>

            <Typography className={styles.content} align="justify">
              If you have any questions about the Terms and Conditions, or need to provide notice
              to, or communicate with, InMed under the Terms and Conditions, please contact InMed
              using the following contact details:
            </Typography>

            <Typography>India</Typography>
            <Typography>100, NCL Innovation Park</Typography>
            <Typography>Dr Homi Bhabha Road, NCL Colony</Typography>
            <Typography>Pashan, Pune 411028</Typography>
            <Typography className={styles.content} align="justify">
              Maharashtra, India
            </Typography>

            <Typography className={styles.content} align="justify">
              InMed may provide notices or communications to you on the Websites and/or Platforms
              and you agree that such notices shall constitute notice to you whether or not you
              actually access the notice.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TermsAndConditions;
