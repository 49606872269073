const INITIAL_STATE = {
  patientData: [],
  filter: {
    filterPatientId: '',
    filterPatientAge: '',
    filterPatientGender: '',
    filterPatientNSID: '',
    filterReportStatus: '',
    filterBySite: '',
    filterDate: '',
    skip: 0,
    limit: 10
  },
  totalStudies: 0
};

const SET_DATA = 'SET_DATA';
const SET_FILTER = 'SET_FILTER';
const SET_TOTAL = 'SET_TOTAL';

export const worklistTableDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_DATA:
      return { ...state, patientData: payload };
    case SET_FILTER:
      return { ...state, filter: { ...state.filter, [payload.key]: payload.value } };
    case SET_TOTAL:
      return { ...state, totalStudies: payload };
    case 'CLEAR_FILTER':
      return { ...state, filter: INITIAL_STATE.filter };
    default:
      return state;
  }
};
