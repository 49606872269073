import React, { useState } from 'react';
import { Button, Grid, Modal, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {makeStyles} from '@material-ui/styles'

const UploadStudyModal = (props) => {
  const { open, onClose, modalMessage } = props;
  const [modalStyle] = useState(getModalStyle);
  const [gridItemStyle] = useState(getGridItemStyle);
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      borderRadius:'10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  const Classes = useStyles();

  function getModalStyle() {
    const top = 33;
    const left = 25;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${left}%, ${top}%)`,
    };
  }

  function getGridItemStyle() {
    return {
      display: 'flex',
      justifyContent: 'center',
      mt: 2
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={Classes.paper}>
      <Typography sx={{textAlign: 'center'}}>{modalMessage}</Typography>
      <Typography sx={{textAlign: 'center'}}>Would you like to upload another case or go back to worklist?</Typography>
      <Grid container spacing={3} sx={{justifyContent: 'center'}}>
        <Grid item xs={5} sx={gridItemStyle}>
          <Button 
            variant="contained"
            color="primary"
            sx={{flex: 1}}
            onClick={onClose}>
            {/* <Link component={RouterLink} to="/dashboard/worklist/upload" underline="none"> */}
              <Typography sx={{color: 'common.white'}}>Upload Another Case</Typography>
            {/* </Link> */}
          </Button>
        </Grid>
        <Grid item xs={5} sx={gridItemStyle}>
          <Button 
            variant="contained"
            color="primary"
            sx={{flex: 1}}>
            <Link component={RouterLink} to="/dashboard/worklist" underline="none">
              <Typography sx={{color: 'common.white'}}>Go Back To WorkList</Typography>
            </Link>
          </Button>
        </Grid>
      </Grid>
      </div>
    </Modal>
  );
};

export default UploadStudyModal;
