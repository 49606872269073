import React, { useState } from 'react';
import { Button, Grid, Modal, Typography, Link, InputLabel, TextField, MenuItem, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {makeStyles} from '@material-ui/styles'
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

const REASONS = [
    {id: 1, value: "Artifact present"},
    {id: 1, value: "Pathology present"},
    {id: 1, value: "Protocol parameters mismatch"},
    {id: 1, value: "Repeated Case"},
    {id: 1, value: "Duplicate Case"},
    {id: 1, value: "Improper FOV"},
    {id: 1, value: "Contrast Study"},

];

const RejectReportModal = (props) => {
  const { open, onClose, rejectData, handleMarkReject } = props;
  const [modalStyle] = useState(getModalStyle);
  const [gridItemStyle] = useState(getGridItemStyle);
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      borderRadius:'10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  const Classes = useStyles();

  function getModalStyle() {
    const top = 33;
    const left = 25;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${left}%, ${top}%)`,
    };
  }
  

  function getGridItemStyle() {
    return {
      display: 'flex',
      justifyContent: 'center',
      mt: 2
    }
  }
  
  const rejectReasonSchema = Yup.object().shape({
    rejectReason:Yup.string().required('Required'),
  })

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={Classes.paper}>
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          Please select a reason for rejecting the case
        </Typography>
        <Formik
          validationSchema={rejectReasonSchema}
          initialValues={{ rejectReason: '' }}
          onSubmit={(values) => handleMarkReject(rejectData, values)}
        >
          {({ handleSubmit, handleChange, handleBlur, touched, errors, values }) => (
            <>
              <TextField
                select
                name="rejectReason"
                variant="outlined"
                label="Reason"
                sx={{ width: '100%', mt: 3 }}
                placeholder="Reason"
                value={values.rejectReason}
                onChange={(e) => handleChange('rejectReason')(e.target.value)}
                onBlur={handleBlur("rejectReason")}
                helperText={touched.rejectReason && errors.rejectReason}
                error={Boolean(touched.rejectReason && errors.rejectReason)}
              >

                {REASONS.map((reason, index) => (
                  <MenuItem value={reason.value} key={index}>
                    {reason.value}
                  </MenuItem>
                ))}
              </TextField>

              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}
                spacing={3}
              >
                <Button variant="contained" color="primary" sx={{ width: '20%', mr: 2 }} onClick={handleSubmit}>
                  Submit
                </Button>
                <Button variant="contained" color="primary" sx={{ width: '20%' }} onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RejectReportModal;
