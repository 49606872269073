import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import switchIcon from '@iconify/icons-eva/swap-fill';

// material
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Typography
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
// components
import { logoutApi } from '../../api/login/logoutApi';
import MenuPopover from '../../components/MenuPopover';
import { setLogoutStatus } from '../../redux/actions/authActions';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
//

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/dashboard/app'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '/dashboard/profile'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = useState({ isOpen: false, vertical: 'top', horizontal: 'center' });
  const { vertical, horizontal, isOpen } = state;
  const navigate = useNavigate()
  // redux state
  const { user, token, passcode } = useSelector((state) => state.loggedInUser);
  const hasBleedSubscription = useSelector((state) => state.loggedInUser.user.hasBleedSubscription);

  const displayName = user.username && user.username.toUpperCase();
  const userEmail = user.email;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchClick = () => {
    setOpen((prev) => !prev);
    navigate('/siteaccess')
  };

  const handleLogout = async () => {
    const res = await logoutApi(token, passcode);
    if (res && res.message === 'Network Error') {
      setResponseState({ error: true, msg: res.message });
      setState({ isOpen: true, vertical: 'top', horizontal: 'right' });
    } else if (res && res.status === 200) {
      localStorage.removeItem('loggedInUser');
      setAuthorizationToken('');
      dispatch(setLogoutStatus());
      navigate('/login')
    } else if (res && res.status === 401) {
      setResponseState({ error: true, msg: res.response.data.msg });
      setState({ isOpen: true, vertical: 'top', horizontal: 'right' });
    }
  };

  return (
    <>
      <Snackbar
        severity="red"
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
      </Snackbar>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt={displayName} src="photoURL" style={{ backgroundColor: '#16B264' }} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        {hasBleedSubscription ? (
          <MenuItem
            onClick={handleSwitchClick}
            sx={{ typography: 'body2', py: 1, px: 2.5, alignItems: 'flex-start' }}
          >
            <Box
              component={Icon}
              icon={switchIcon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            <Box
              sx={{
                width: '75%',
                whiteSpace: 'break-spaces'
              }}
              component="span"
            >
              Product Selection 
            </Box>
          </MenuItem>
        ) : null}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
