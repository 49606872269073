import React from 'react';

import UserForm from '../form';
import { useEditUser } from './hooks';

const EditUser = () => {
  const { handleEditUser, selectedUser } = useEditUser();
  return <UserForm handleUserForm={handleEditUser} btn="Update" fieldValues={selectedUser} isEdit />;
};

export default EditUser;
