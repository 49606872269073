import React,{useState} from 'react';
import {FormikProvider,Form,useFormik} from "formik";
import {Link} from 'react-router-dom';
import {Breadcrumbs,TextField,InputLabel,Box,Button,SnackbarContent,Snackbar} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';



const CreateEntity = () => {

    const marginStyles = {
        margin:'15px 0 10px 5px'
    }

    const [responseState,setResponseState] = useState({error:false,msg:''});
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

      const { vertical, horizontal, open } = state;

      const handleClose = () => {
        setState({ ...state, open: false });
      };

      const entitySchema = Yup.object().shape({
        entityName: Yup.string().required("Required"),
      })


    const formik = useFormik({
        initialValues:{
            entityName:'',
            entityDescription:''
        },
        validationSchema:entitySchema,
        onSubmit: async ()=>{
            console.log("formik Values",values);

            const res =  await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/entities`,values)
                                    .catch((error)=> error);

            console.log("response",res);

            if(res){
                if(res.message==="Network Error"){
                  setResponseState({error:true,msg:res.message});
                  setState({ open: true, vertical:'top',horizontal:'right'});
                } else if(res.status===200){
                  console.log("res data",res.data.msg);
                  setResponseState({error:false,msg:res.data.msg});
                  setState({ open: true, vertical:'top',horizontal:'right'});
                  values.entityName = '';
                  values.entityDescription = '';
                } else if(res.status===409){
                  setResponseState({error:true,msg:res.data.msg});
                  console.log("responseError",res.data.msg);
                  setState({ open: true, vertical:'top',horizontal:'right'});

                }
              }
        }
    })

    const {  values, handleSubmit , getFieldProps , errors , touched } = formik;



          return  (
          <FormikProvider value={formik}>
             <Form autoComplete='off' onSubmit={handleSubmit} >

             <Snackbar
        severity={responseState.error ? 'red' : 'green' }
        autoHideDuration={5000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
     >
        <SnackbarContent style={{backgroundColor:responseState.error ? 'red' : 'green'}} message={responseState.msg} />
      </Snackbar>

                <Breadcrumbs aria-label="breadcrumb" my={3}>
                    <Link color="inherit" to="/dashboard/entities" >
                        Entities
                    </Link>
                    <Link
                    color="textPrimary"
                    to="/dashboard/entities/create-entity"
                    aria-current="page"
                    >
                        Create Entity
                    </Link>
                </Breadcrumbs>



                <InputLabel htmlFor="entityName"  style={marginStyles}> Name <span style={{color:'red'}}>*</span></InputLabel>
                <TextField  name="entityName" variant="outlined" label="Entity Name" style={{width:'70%'}} {...getFieldProps('entityName')}
                 helperText={touched.entityName && errors.entityName}
                 error={Boolean(touched.entityName && errors.entityName)} />


                <InputLabel htmlFor="entityDescription"  style={marginStyles}>Description</InputLabel>
                <TextField  name="entityDescription" variant="outlined" label="Entity Description" style={{width:'70%'}} {...getFieldProps('entityDescription')}/>



                <Box mt={3}>
                <Button type="reset" variant="contained" color="primary" style={{marginRight:"20px"}} > Reset </Button>
                <Button variant="contained" color="primary" type="submit"> Create </Button>
                </Box>

            </Form>
        </FormikProvider>
    )

};

export default CreateEntity;
