import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles({
  form: {
    margin: 'auto'
    // width: '450px',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '90%'
  },
  mainHeading: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center'
  },
  formBody: {
    width:'90%',
    margin:'auto'
    // marginTop: '15%',
    // padding: '30px 20px 20px',
    // backgroundColor: '#fff',
    // border: '2px solid white',
    // borderRadius: '10px',
    // boxShadow: '0 0 2px 2px lightgrey'
  },
  H2: {
    // margin: '10px 22%'
    display: 'flex',
    justifyContent: 'center'
  },
  para: {
    textAlign: 'center',
    color: 'gray',
    fontSize: '13px',
    paddingBottom: '7px'
  },
  input: {
    margin: '10px 0'
  },
  btn: {
    // margin: '5% auto',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center'
  },
  back: {
    // marginLeft: '15px'
    // margin: '0 auto',
    padding: '20px'
    // display: 'flex',
    // justifyContent: 'center'
  }
});

export const useResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const classes = useStyles();

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/,
      'Password must contain 8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character'
  ),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      const token = searchParams.get('token');
      const payload = {
        token,
        password: value.password,
        confirmPassword: value.confirmPassword
      };
      axios
        .post(`${process.env.REACT_APP_API_SERVER_URL}/api/users/reset-password`, payload)
        .then((res) => {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        })
        .catch((error) => {
          setResponseState({ error: true, msg: error.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        });
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return {
    showPassword,
    showConfirmPassword,
    responseState,
    vertical,
    horizontal,
    open,
    handleClose,
    classes,
    formik,
    handleShowPassword,
    handleShowConfirmPassword
  };
};
