import { useDispatch } from 'react-redux';
// import { sentenceCase } from 'change-case';
import { useState } from 'react';
// material

// components
import { useQuery } from 'react-query';
import { setUserId } from '../../../redux/actions/authActions';
import { useApi } from '../../../config/api';
import { showSnackbar } from '../../../components/snackbar/actions';

export const useSiteList = () => {

  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    siteName: '',
    sort:{name:1},
    order:'desc',
    orderBy:'createdAt',
    address: ''
  });

  const handleFilters = (e) => {
    setParams((prev) => ({ ...prev, [e.target.name]: e.target.value,offset:0 }));
  };

  const { api, apiEndpoints } = useApi();

  const { data: { data: { data: sites = [], count = 0 } = {} } = {},isLoading:sitesLoading} = useQuery(
    ['sites', params],
    () =>
      api().get(apiEndpoints.sites, {
        params
      }),
    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const dispatch = useDispatch();

  const handleSiteGetId = (id) => {
    dispatch(setUserId(id));
  };

  const handleRequestSort = (event, property) => {
    if (property === 'action' || property === 'sites') return;
    setParams((prev) => {
      // Calculate the new order and orderBy values
      let newOrder = 'asc';
      if (prev.orderBy === property && prev.order === 'asc') {
        // eslint-disable-next-line no-unused-vars
        newOrder = 'desc';
      }
      return {
        ...prev,
        order: newOrder,
        orderBy: property,
        sort: newOrder === 'asc' ? property : `-${property}`
      };
    });
  };

  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * params.limit;
    setParams((prev) => ({ ...prev, offset: newOffset }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({ ...prev, offset: 0, limit: parseInt(event.target.value, 10) }));
  };

  return {
    sites,
    sitesLoading,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSiteGetId,
    handleFilters,
    params,
    count
  };
};
