import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  Grid,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  TextField,
  MenuItem
} from '@material-ui/core';
import SearchFilter from '../../../components/search-filter';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 120,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '13%',
  // color: `${theme.palette.grey[500_80]}`,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '13%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

WorkListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filteredData: PropTypes.object,
  onFilterChange: PropTypes.func
};

export default function WorkListToolbar({ numSelected, filteredData, onFilterChange }) {
  const {
    filterPatientNSID,
    filterPatientAge,
    filterPatientGender,
    filterPatientId,
    filterReportStatus,
    filterBySite,
    filterDate
  } = filteredData;
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container spacing={2} mt={1}>
          <SearchFilter
            handleSearch={onFilterChange}
            name="filterPatientNSID"
            searchPlaceholder="NSID"
            searchedValue={filterPatientNSID}
          />

          <SearchFilter
            handleSearch={onFilterChange}
            name="filterPatientId"
            searchPlaceholder="Patient Id"
            searchedValue={filterPatientId}
          />

          <SearchFilter
            handleSearch={onFilterChange}
            name="filterPatientAge"
            searchPlaceholder="Age"
            searchedValue={filterPatientAge}
          />

          <SearchFilter
            handleSearch={onFilterChange}
            name="filterBySite"
            searchPlaceholder="Site"
            searchedValue={filterBySite}
          />

          {/* <SearchFilter
            handleSearch={onFilterChange}
            name="filterReportStatus"
            searchPlaceholder="Status"
            searchedValue={filterReportStatus}
          /> */}

          <TextField
              style={{ width: '15%', margin: '10px 5px 2px 5px' }}
              select
              label="Status"
              value={filterReportStatus}
              name="filterReportStatus"
              onChange={onFilterChange}
          >
              <MenuItem value='Complete'>Complete</MenuItem>
              <MenuItem value='Processing'>Processing</MenuItem>
              <MenuItem value='Completed'>Completed</MenuItem>
              <MenuItem value='Complete 7 S'>Complete 7 S</MenuItem>
              <MenuItem value='Complete 11 S'>Complete 11 S</MenuItem>
              <MenuItem value='Complete 13 S'>Complete 13 S</MenuItem>
              <MenuItem value='Complete 21 S'>Complete 21 S</MenuItem>
              <MenuItem value='Complete 30 S'>Complete 30 S</MenuItem>
              <MenuItem value='In Review'>In Review</MenuItem>
              <MenuItem value='Initiated'>Initiated</MenuItem>
              <MenuItem value='Failed'>Failed</MenuItem>
              <MenuItem value='Rejected'>Rejected</MenuItem>

          </TextField>

          <TextField
            style={{ width: '15%', margin: '10px 5px 2px 5px' }}
            select
            label="Gender"
            value={filterPatientGender}
            onChange={onFilterChange}
            name="filterPatientGender"
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </TextField>

          <SearchStyle
            type="date"
            sx={{ color: '#919EAB' }}
            placeholder="Site"
            style={{ margin: '10px 5px 2px 5px' }}
            value={filterDate}
            onChange={onFilterChange}
            name="filterDate"
          />
        </Grid>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
