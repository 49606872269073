import { Icon } from '@iconify/react';
import { capitalize } from 'lodash';
// import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';

import { Assignment, Edit } from '@material-ui/icons';
import moment from 'moment';

// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import TableHeader from '../../../components/table-header/index';
import { iconStyle } from './styles';
import { useSiteList } from './hooks';
import ListToolbar from '../../../components/list-toolbar';
import SearchFilter from '../../../components/search-filter';
import DeleteSite from '../delete-site';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Site Name', alignRight: false },
  { id: 'shortName', label: 'Short Name', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

export default function SiteList() {
  const {
    sites,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSiteGetId,
    params,
    handleFilters,
    count,
    sitesLoading
  } = useSiteList();
  return (
    <Page title="Sites | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sites
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/sitelist/create-site"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Site
          </Button>
        </Stack>

        <Card>
          <ListToolbar>
            <SearchFilter
              searchPlaceholder="Site Name"
              name="siteName"
              handleSearch={handleFilters}
              searchedValue={params.siteName}
            />
            <SearchFilter
              searchPlaceholder="Address"
              name="address"
              handleSearch={handleFilters}
              searchedValue={params.address}
            />
          </ListToolbar>
          {sitesLoading?(
            <CircularProgress
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto'
              }}
            />
          ):(
            <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHeader
                        order={params.order}
                        orderBy={params.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={sites.length}
                        onRequestSort={handleRequestSort}
                        showSelectAll={false}
                      />
                      <TableBody>
                        {sites.map((row) => {
                          const { _id: patientId, name, address, createdAt, shortName } = row;

                          return (
                            <TableRow hover key={patientId} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{capitalize(name) || 'NA'}</TableCell>
                              <TableCell align="left">{shortName || 'NA'}</TableCell>
                              <TableCell align="left">{address || 'NA'}</TableCell>
                              <TableCell align="left">
                                {moment(createdAt).format('MMM DD, YYYY, h:mm a') || 'NA'}
                              </TableCell>
                              <TableCell align="left">
                                <RouterLink to="/dashboard/sitelist/site-details">
                                  <Tooltip title="Details" arrow>
                                    <Assignment
                                      style={iconStyle}
                                      onClick={() => {
                                        handleSiteGetId(patientId);
                                      }}
                                    />
                                  </Tooltip>
                                </RouterLink>
                                <RouterLink
                                  to={{
                                    pathname: `/dashboard/sitelist/edit-site/${patientId}`,
                                    state: { siteId: patientId }
                                  }}
                                >
                                  <Tooltip title="Edit" arrow>
                                    <Edit style={iconStyle} />
                                  </Tooltip>
                                </RouterLink>
                                <DeleteSite callback={() => { }} id={patientId} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={params.limit}
                  page={Math.floor(params.offset / params.limit)}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
          )}

        </Card>
      </Container>
    </Page>
  );
}
