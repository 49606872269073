import React from 'react';

import EntityForm from '../form/index';
import { useEditEntity } from './hooks';

const EditEntity = () => {
  const { handleEditEntity, selectedEntity } = useEditEntity();
  console.log("indexpage of editentity",selectedEntity)
  return (
    <EntityForm handleEntityForm={handleEditEntity} btn="Update" fieldValues={selectedEntity} isEdit />
  );
};

export default EditEntity;
