import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useEditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const { data: { data: { data: selectedUser = {} } = {} } = {} } = useQuery(
    ['userDetail', id],
    () => api().get(apiEndpoints.singleUser(id)),

    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const { mutate: handleEditUser } = useMutation(
    (values) => {
      const payload = values;
      payload.sites = values.sites.map((site) => site._id);
      return api().patch(apiEndpoints.singleUser(id), payload);
    },
    {
      onSuccess: (response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.msg
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error.response.data.msg
          })
        );
      }
    }
  );

  return { handleEditUser, selectedUser };
};
