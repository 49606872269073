import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { OutlinedInput } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '15%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '22%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const SearchFilter = ({ searchPlaceholder, handleSearch, searchedValue, name }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(searchedValue);
  }, [searchedValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchText = useCallback(
    _.debounce((e) => {
      handleSearch(e);
    }, 600),
    []
  );

  return (
    <SearchStyle
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        setSearchText(e);
      }}
      name={name}
      placeholder={searchPlaceholder}
      style={{ margin: '10px 5px 2px 5px' }}
    />
  );
};

SearchFilter.propTypes = {
  searchPlaceholder: PropTypes.string,
  handleSearch: PropTypes.func,
  name: PropTypes.string,
  searchedValue: PropTypes.string
};

SearchFilter.defaultProps = {
  searchPlaceholder: 'Search...'
};

export default SearchFilter;
