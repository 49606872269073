import axios from 'axios';

export const logoutApi = (token, passcode) => (

    axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/logout`, {
        currentToken: token,
        passcode
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .catch((error) => (error))
);