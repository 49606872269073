import React, { useState } from 'react';
import {
  InputLabel,
  Breadcrumbs,
  LinearProgress,
  Snackbar,
  SnackbarContent,
  Button,
  Box,
  RadioGroup,
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  MenuItem,
  Select
} from '@material-ui/core';
import { FormikProvider, Form, useFormik } from 'formik';
// import * as Yup from "yup";
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
// i    mport { v4 as uuidv4 } from 'uuid';
import { Link, useLocation } from 'react-router-dom';
import { mixed, object } from 'yup';

const UploadSegmentation = () => {
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [progress, setProgress] = useState(false);
  const [caseFiles, setCaseFiles] = useState(undefined);

  const location = useLocation();

  const studyid = location.search.substring(9);

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const formik = useFormik({
    initialValues: {
      studyId: studyid,
      caseFiles: []
    },
    validationSchema: object({
      caseFiles: mixed().required('Please upload at least one file')
    }),
    onSubmit: async (values) => {
      try {
        const segmentationFile = new FormData();
        segmentationFile.append('studyId', values.studyId);
        for (let i = 0; i < (caseFiles ? caseFiles.length : 0); i += 1) {
          segmentationFile.append('caseFiles', caseFiles[i]);
        }
        const res = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/studies/upload-segmentation`,
          segmentationFile,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              setProgress(Math.round((loaded * 100) / total));
            }
          }
        );
        setResponseState({ error: false, msg: res.data.message });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
        setProgress(false);
        setCaseFiles('');
      } catch (error) {
        setProgress(false);
        setResponseState({ error: true, msg: error?.data?.message || 'No files uploaded.' });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
      }

    }
  });

  const marginStyles = {
    marginTop: '15px',
    marginBottom: '10px'
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/worklist">
            WorkList
          </Link>
          <Link color="textPrimary" to="/dashboard/worklist/upload" aria-current="page">
            Upload Study
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="caseFiles" style={marginStyles}>
          Upload Docs <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <input
          id="caseFiles"
          name="caseFiles"
          type="file"
          multiple
          onChange={(e) => {
            setCaseFiles(e.target.files);
          }}
        />
        {touched.caseFiles && errors.caseFiles ? (
          <FormHelperText>{errors.caseFiles}</FormHelperText>
        ) : null}
        <Box mt={3}>
          {progress ? <LinearProgress variant="determinate" value={progress} /> : <></>}
        </Box>

        <Box mt={3}>
          <LoadingButton variant="contained" color="primary" type="submit">
            {' '}
            Upload{' '}
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default UploadSegmentation;
