import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import DeleteConfirmation from '../../../../components/delete-confirmation';
import { iconStyle } from '../../../../constants';
import { useDeleteStudy } from './hook';

const DeleteStudy = ({ id, refetchStudy }) => {
  const { openModal, handleModal, handleDeleteStudy } = useDeleteStudy({
    id,
    callback: () => {
      handleModal();
      refetchStudy();
    }
  });

  return (
    <>
      <Tooltip title="Delete" arrow>
        <Delete style={iconStyle} onClick={handleModal} />
      </Tooltip>
      <DeleteConfirmation
        handleDelete={handleDeleteStudy}
        handleModal={handleModal}
        openModal={openModal}
        message="Do You want to Delete this study permanently ?"
      />
    </>
  );
};

export default DeleteStudy;
