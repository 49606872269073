import {
  Box,
  Button,
  Card,
  Checkbox,
  InputBase,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const WorklistRepBVA = ({ getFieldProps, edit, handleCheckChange, checked }) => {
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const dataStyles = {
    color: 'red',
    fontWeight: 'bold',
    padding: '10px 16px 10px 10px'
  };

  const cellStyle = {
    '& th': {
      padding: '8px 16px 8px 10px',
      color: 'white'
    },
    '& td': {
      padding: '8px 16px 8px 10px'
    }
  };

  const cardStyle = {
    width: '100%',
    marginTop: '20px'
  };
  const inputStyle = {
    border: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    borderRadius: '8px',
    '& input': {
      marginLeft: '8px'
    }
  };
  const SiteID = getFieldProps('SiteID').value;

  const BVA_DATA = [
    { id: 2, structure: 'Whole brain', refid: 22, prcntid: 32, colorid: 42 },
    { id: 21, structure: 'Ventricles', refid: 221, prcntid: 321, colorid: 421 },
    { id: 25, structure: 'L Hippocampus', refid: 225, prcntid: 325, colorid: 425 },
    { id: 26, structure: 'R Hippocampus', refid: 226, prcntid: 326, colorid: 426 },
    { id: 33, structure: 'Mid brain', refid: 233, prcntid: 333, colorid: 433 },
    { id: 34, structure: 'Pons', refid: 234, prcntid: 334, colorid: 434 },
    { id: 35, structure: 'Brain stem', refid: 235, prcntid: 335, colorid: 435 },
    { id: 36, structure: 'R Amygdala', refid: 236, prcntid: 336, colorid: 436 },
    { id: 37, structure: 'L Amygdala', refid: 237, prcntid: 337, colorid: 437 },
    // { id:38, structure: 'Total Amygdala', refid:238, prcntid:338, colorid:438},
    { id: 39, structure: 'R Caudate Nucleus', refid: 239, prcntid: 339, colorid: 439 },
    { id: 40, structure: 'L Caudate Nucleus', refid: 240, prcntid: 340, colorid: 440 },
    // { id:41, structure: 'Total Caudate Nucleus', refid:241, prcntid:341, colorid:441},
    { id: 42, structure: 'R Cerebellum', refid: 242, prcntid: 342, colorid: 442 },
    { id: 43, structure: 'L Cerebellum', refid: 243, prcntid: 343, colorid: 443 },
    // { id:44, structure: 'TotalCerebellum', refid:244, prcntid:344, colorid:444},
    { id: 45, structure: 'R Cerebrum ', refid: 245, prcntid: 345, colorid: 445 },
    { id: 46, structure: 'L Cerebrum', refid: 246, prcntid: 346, colorid: 446 },
    // { id:47, structure: 'Total Cerebrum', refid:247, prcntid:347, colorid:447},
    { id: 48, structure: 'R Globus Pallidus', refid: 248, prcntid: 348, colorid: 448 },
    { id: 49, structure: 'L Globus Pallidus', refid: 249, prcntid: 349, colorid: 449 },
    // { id:50, structure: 'Total Globus Pallidus', refid:250, prcntid:350, colorid:450},
    { id: 52, structure: 'R Putamen', refid: 252, prcntid: 352, colorid: 452 },
    { id: 53, structure: 'L Putamen', refid: 253, prcntid: 353, colorid: 453 },
    // { id:54, structure: 'Total Putamen', refid:254, prcntid:354, colorid:454},
    { id: 55, structure: 'R Thalamus', refid: 255, prcntid: 355, colorid: 455 },
    { id: 56, structure: 'L Thalamus', refid: 256, prcntid: 356, colorid: 456 },
    // { id:57, structure: 'Total Thalamus', refid:257, prcntid:357, colorid:457},
    { id: 59, structure: 'Total Hippocampus', refid: 259, prcntid: 359, colorid: 459 },

    { id: 60, structure: 'R Frontal-Lobe', refid: 260, prcntid: 360, colorid: 460 },
    { id: 61, structure: 'L Frontal-Lobe', refid: 261, prcntid: 361, colorid: 461 },
    // { id: 62, structure: 'Total Frontal-Lobe', refid: 262, prcntid: 362, colorid: 462 },
    { id: 63, structure: 'R Occipital-Lobe', refid: 263, prcntid: 363, colorid: 463 },
    { id: 64, structure: 'L Occipital-Lobe', refid: 264, prcntid: 364, colorid: 464 },
    // { id: 65, structure: 'Total Occipital-Lobe', refid: 265, prcntid: 365, colorid: 465 },
    { id: 66, structure: 'R Parietal-Lobe', refid: 266, prcntid: 366, colorid: 466 },
    { id: 67, structure: 'L Parietal-Lobe', refid: 267, prcntid: 367, colorid: 467 },
    // { id: 68, structure: 'Total Parietal-Lobe', refid: 268, prcntid: 368, colorid: 468 },
    { id: 69, structure: 'R Temporal-Lobe', refid: 269, prcntid: 369, colorid: 469 },
    { id: 70, structure: 'L Temporal-Lobe', refid: 270, prcntid: 370, colorid: 470 },
    // { id: 71, structure: 'Total Temporal-Lobe', refid: 271, prcntid: 371, colorid: 471 }
  ];

  const dummy = {
    value: 23
  };

  const ICV_DATA = [
    { id: 1, analysis: 'Total Intracranial Volume (ICV)', prcntid: 1, colorid: 41 },
  ];
  const VDA_DATA = [
    { id: 1, analysis: 'Hippocampus Asymmetry Index **' },
    { id: 3, analysis: 'Temporal Lobe Asymmetry Index' }
  ];

  const CA_DATA = [
    { id: 51, structure: 'Grey Matter', refid: 251, prcntid: 351, colorid: 451 },
    { id: 58, structure: 'White Matter', refid: 258, prcntid: 358, colorid: 458 },
  ]

  const colorOptions = [
    { label: 'RED', value: 'red' },
    { label: 'BLUE', value: 'blue' },
    { label: 'BLACK', value: 'black' }
  ];

  const colorOptions2 = [
    { label: 'RED', value: 'red' },
    { label: 'BLUE', value: 'blue' },
    { label: 'BLACK', value: 'black' },
    { label: 'ORANGE', value: 'orange' }
  ];

  const recalculateVolumes = async () => {
    const PatientID = getFieldProps('StudyPatientID').value;
    const SiteID = getFieldProps('SiteID').value;
    const Age = getFieldProps('PatientAge').value;
    const Gender = getFieldProps('PatientGender').value;
    const studyId = getFieldProps('studyId').value;
    const values = {
      PatientID,
      SiteID,
      Age,
      Gender,
      studyId
    };
    await axios
      .post(`${process.env.REACT_APP_API_SERVER_URL}/api/studies/recalculate-report`, values, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((res) => {
        if (res) {
          if (res.message === 'Network Error') {
            setResponseState({ error: true, msg: res.data.message });
            setState({ open: true, vertical: 'top', horizontal: 'right' });
          } else if (res.status === 200) {
            setResponseState({ error: false, msg: res.data.message });
            setState({ open: true, vertical: 'top', horizontal: 'right' });
          }
        }
      });
  };
  const { user } = useSelector((state) => state.loggedInUser);
  const addComment = getFieldProps('additionalComment');
  const output = getFieldProps('output');
  const mrpi = getFieldProps('mrpi');
  const processingStatus = getFieldProps('processingStatus').value;
  const Age = getFieldProps('PatientAge').value;
  let newBvaData = [];
  const newId = [
    2, 21, 25, 26, 33, 34, 35, 42, 43, 45, 46, 51, 58, 60, 61, 63, 64, 66, 67, 69, 70
  ];
  const shortReportStatus = ['Conplete', 'Complete 11 S', 'Complete 13 S', 'Complete 7 S', 'Complete 21 S'];
  // if (shortReportStatus.includes(processingStatus)) {
  //   newBvaData = BVA_DATA.filter((data) => newId.includes(data.id));
  // } else {
    newBvaData = BVA_DATA;
  // }

  const bvaTable1 = newBvaData.slice(0, newBvaData.length / 2)
  const bvaTable2 = newBvaData.slice(newBvaData.length / 2)

  const bvaTableData = (bvaData) => (
    <TableBody>
      {bvaData.map((row) => {
        const { id, structure, refid, prcntid } = row;
        const vol = getFieldProps(`bva1${id}`);
        const ref = getFieldProps(`bva${refid}`);
        const icv = prcntid === 1 ? { value: 100 } : getFieldProps(`bva${prcntid}`);
        let color = getFieldProps(`colorbva1${id}`) || 'red';
        const [start, end] =
          ref.value === undefined
            ? [0, 0]
            : ref.value.split('-').map((ele) => parseFloat(ele));
        const icvNumberValue = parseFloat(icv.value);
        let colorStyle;
        let fontWeightStyle;
        const SiteID = getFieldProps('SiteID').value;

        if (icvNumberValue < parseFloat(start) && checked) {
          colorStyle = color.value || 'red';
          fontWeightStyle = 'bold';
        } else if (icvNumberValue === parseFloat(start) && checked) {
          colorStyle = color.value || 'blue';
          fontWeightStyle = 'bold';
        } else {
          colorStyle = color.value || 'black';
          fontWeightStyle = 'normal';
        }

        if (
          id === 21 &&
          parseInt(icvNumberValue * 10, 10) > parseInt(end * 10, 10) &&
          checked
        ) {
          colorStyle = 'red';
          fontWeightStyle = 'bold';
        } else if (
          id === 21 &&
          parseInt(icvNumberValue * 10, 10) < parseInt(end * 10, 10)
        ) {
          colorStyle = 'black';
          fontWeightStyle = 'normal';
        }

        if (SiteID === '605c1c5a7365ba2c7923e602') {
          if (icvNumberValue < parseFloat(start) && checked) {
            colorStyle = color.value || 'blue';
            fontWeightStyle = 'bold';
          } else {
            colorStyle = color.value || 'black';
            fontWeightStyle = 'normal';
          }
        }

        if (id === 1 || id === 51 || id === 58) {
          colorStyle = 'black';
          fontWeightStyle = 'normal';
        }

        color = { ...color, value: colorStyle };
        return (
          <TableRow key={id}>
            <TableCell sx={{ width: '25%' }}> {structure} </TableCell>
            <TableCell>
              {edit ? <InputBase sx={inputStyle} {...vol} /> : vol?.value || 'NA'}
            </TableCell>

            <TableCell
              sx={{ ...dataStyles, color: colorStyle, fontWeight: fontWeightStyle }}
            >
              {edit ? <InputBase sx={inputStyle} {...icv} /> : icv?.value || 'NA'}
            </TableCell>
            {(id !== 51 && id !== 58) && user.role === 'Admin' && checked && Age > 11 ? (
              <>
                <TableCell>
                  {edit ? <InputBase sx={inputStyle} {...ref} /> : ref?.value || 'NA'}
                </TableCell>
              </>
            ) : (
              checked && (
                <>
                  <TableCell>
                    {edit ? <InputBase sx={inputStyle} {...ref} /> : ref?.value}
                  </TableCell>
                </>
              )
            )}

            {user.role === 'Admin' && id !== 51 && id !== 58 && (
              <TableCell>
                {edit ? (
                  <TextField
                    select
                    sx={{ width: '100%' }}
                    {...color}
                    SelectProps={{
                      native: false
                    }}
                  >
                    {colorOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  color?.value
                )}
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  )

  return (
    <div>
      <div>
        <br />
        <h3>MRPI</h3>
        <Card sx={inputStyle}>
          {edit ? (
            <InputBase fullWidth label="fullWidth" id="fullWidth" {...mrpi} />
          ) : (
            mrpi.value || 'NA'
          )}
        </Card>
      </div>
      <div>
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <h3>Volumetric Derived Analysis</h3>
          {user.role === 'Admin' && (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-evenly">
                <Button
                  style={{ margin: '10px 5px 8px 10px', padding: '6px 6px 6px 6px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => recalculateVolumes()}
                >
                  Recalculate Volume
                </Button>
                <Typography style={{ marginLeft: '15px' }} variant="h7">
                  Show Reference Range
                </Typography>
                <Checkbox checked={checked} onChange={handleCheckChange} />
              </Box>
            </>
          )}
        </Box>
        <Card sx={cardStyle}>
          <TableContainer>
            <Table sx={cellStyle}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'primary.main' }}>
                  <TableCell> Analysis </TableCell>
                  <TableCell> Output </TableCell>
                  {user.role === 'Admin' && checked && Age > 11 ? (
                    <TableCell> Reference </TableCell>
                  ) : (
                    checked && <TableCell> Reference </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ICV_DATA.map((row) => {
                  const { id, analysis } = row;
                  const output = getFieldProps(`bva1${id}`);
                  const reference = { value: "NA" };
                  const color = id === 1 ? getFieldProps(`colorbva11`) : getFieldProps(`colorvda1`);
                  const colorStyle = id === 2 || id === 1 ? color.value : 'black';
                  return (
                    <TableRow key={id}>
                      <TableCell>{analysis}</TableCell>
                      <TableCell sx={{ ...dataStyles, color: colorStyle }}>
                        {edit ? <InputBase sx={inputStyle} {...output} /> : output.value}
                      </TableCell>
                      {user.role === 'Admin' && checked && Age > 11 ? (
                        <>
                          <TableCell>
                            {edit ? <InputBase sx={inputStyle} {...reference} /> : reference?.value || 'NA'}
                          </TableCell>
                        </>
                      ) : (
                        checked && (
                          <>
                            <TableCell>
                              {edit ? <InputBase sx={inputStyle} {...reference} /> : reference?.value}
                            </TableCell>
                          </>
                        )
                      )}
                    </TableRow>
                  );
                })}
                {VDA_DATA.map((row) => {
                  const { id, analysis } = row;
                  const output = getFieldProps(`vda1${id}`);
                  const reference = getFieldProps(`vda2${id}`);
                  const color = id === 1 ? getFieldProps(`colorbva11`) : getFieldProps(`colorvda1`);
                  const colorStyle = id === 1 ? color.value : 'black';
                  return (
                    <TableRow key={id}>
                      <TableCell>{analysis}</TableCell>
                      <TableCell sx={{ ...dataStyles, color: colorStyle }}>
                        {edit ? <InputBase sx={inputStyle} {...output} /> : output.value}
                      </TableCell>
                      {user.role === 'Admin' && checked && Age > 11 ? (
                        <>
                          <TableCell>
                            {edit ? <InputBase sx={inputStyle} {...reference} /> : reference?.value || 'NA'}
                          </TableCell>
                        </>
                      ) : (
                        checked && (
                          <>
                            <TableCell>
                              {edit ? <InputBase sx={inputStyle} {...reference} /> : reference?.value}
                            </TableCell>
                          </>
                        )
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <div>
        <br />
        <h3>Cortical Analysis</h3>
        <Card sx={cardStyle}>
          <TableContainer>
            <Table sx={cellStyle}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'primary.main' }}>
                  <TableCell> Analysis </TableCell>
                  <TableCell> Absolute Volume (ml) </TableCell>
                  <TableCell> Relative Volume (ICV %) </TableCell>
                  {user.role === 'Admin' && checked && Age > 11 ? (
                    <TableCell> Reference </TableCell>
                  ) : (
                    checked && <TableCell> Reference </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {bvaTableData(CA_DATA)}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <div>
        <br />
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            message={responseState.msg}
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
          />
        </Snackbar>
        {user.role==="Admin"?<>
        <h3>Brain Volumetric Analysis</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card sx={{ ...cardStyle, width: '48%' }}>
            <TableContainer>
              <Table sx={cellStyle}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'primary.main' }}>
                    <TableCell> Structure </TableCell>
                    <TableCell> Vol (ml) </TableCell>
                    <TableCell> ICV (%) </TableCell>
                    {user.role === 'Admin' && checked && Age > 11 ? (
                      <TableCell> Reference </TableCell>
                    ) : (
                      checked && <TableCell> Reference </TableCell>
                    )}
                    {user.role === 'Admin' && <TableCell> Color Code </TableCell>}
                  </TableRow>
                </TableHead>
                {bvaTableData(bvaTable1)}
              </Table>
            </TableContainer>
          </Card>
          <Card sx={{ ...cardStyle, width: '48%' }}>
            <TableContainer>
              <Table sx={cellStyle}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'primary.main' }}>
                    <TableCell> Structure </TableCell>
                    <TableCell> Vol (ml) </TableCell>
                    <TableCell> ICV (%) </TableCell>
                    {user.role === 'Admin' && checked && Age > 11 ? (
                      <TableCell> Reference </TableCell>
                    ) : (
                      checked && <TableCell> Reference </TableCell>
                    )}
                    {user.role === 'Admin' && <TableCell> Color Code </TableCell>}
                  </TableRow>
                </TableHead>
                {bvaTableData(bvaTable2)}
              </Table>
            </TableContainer>
          </Card>
        </div>
        </>:<></>}
       
      </div>


      <div>
        <br />
        <h3>Additional Analysis</h3>
        <Card sx={inputStyle}>
          {edit ? (
            <InputBase fullWidth label="fullWidth" id="fullWidth" {...addComment} />
          ) : (
            addComment.value || 'NA'
          )}
        </Card>
      </div>
    </div>
  );
};

export default WorklistRepBVA;
