import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/authentication/login/Login';
import { setLogoutStatus } from './redux/actions/authActions';
import store from './redux/store';

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.removeItem('loggedInUser');
        store.dispatch(setLogoutStatus());
        <Login />;
      }

      return Promise.reject(error.response);
    }
  );

  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}
