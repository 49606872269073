import React from 'react';

import SiteForm from '../form';
import { useAddSite } from './hooks';

const AddSite = () => {
  const { handleAddSite,handleSuccess } = useAddSite();
  return <SiteForm handleSiteForm={handleAddSite} btn="Create" handleSuccess={handleSuccess} />;
};

export default AddSite;
