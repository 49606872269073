import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSiteForm } from './hooks';
import { siteFormSchema } from './validation-schema';

const marginStyles = {
  margin: '15px 0 10px 5px'
};

const SiteForm = (props) => {
  const {
    entities,
    formValues,
    handleFormUpdate,
    resetForm,
    isFormModified,
    handleFormSubmit
  } = useSiteForm(props);

  return (
    <Formik
      validationSchema={siteFormSchema}
      enableReinitialize
      initialValues={{
        siteName: formValues.siteName || '',
        siteShortName: formValues.siteShortName || '',
        siteAddress: formValues.siteAddress || '',
        siteEntityId: formValues.siteEntityId || '',
        institutionName: formValues.institutionName || '',
        sitebleedSubscription: formValues.sitebleedSubscription || false,
        siteOrthoSubscription: formValues.siteOrthoSubscription || false,
        siteOncoSubscription: formValues.siteOncoSubscription || false,
        siteVivoSubscription: formValues.siteVivoSubscription || false
      }}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, errors, ...rest }) => (
        <>
          <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Breadcrumbs aria-label="breadcrumb" my={3}>
                  <Link color="inherit" to="/dashboard/sitelist">
                    SiteList
                  </Link>
                  <Link
                    color="textPrimary"
                    to="/dashboard/sitelist/create-site"
                    aria-current="page"
                  >
                    Site Study
                  </Link>
                </Breadcrumbs>
              </div>
            </div>

            <InputLabel htmlFor="siteName" style={marginStyles}>
              Name <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="siteName"
              variant="outlined"
              label="Site Name"
              style={{ width: '70%' }}
              value={rest.values.siteName}
              onChange={handleFormUpdate}
              helperText={errors.siteName}
              error={Boolean(errors.siteName)}
            />

            <InputLabel htmlFor="siteShortName" style={marginStyles}>
              Short Name <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="siteShortName"
              variant="outlined"
              label="Site Short Name"
              style={{ width: '70%' }}
              value={rest.values.siteShortName}
              onChange={handleFormUpdate}
              helperText={errors.siteShortName}
              error={Boolean(errors.siteShortName)}
            />

            <InputLabel htmlFor="siteAddress" style={marginStyles}>
              Address <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="siteAddress"
              variant="outlined"
              label="Site Address"
              style={{ width: '70%' }}
              value={rest.values.siteAddress}
              onChange={handleFormUpdate}
              helperText={errors.siteAddress}
              error={Boolean(errors.siteAddress)}
            />

            <InputLabel htmlFor="siteEntityId" style={marginStyles}>
              Entity
            </InputLabel>
            <TextField
              select
              name="siteEntityId"
              variant="outlined"
              label="Site Entity"
              style={{ width: '70%' }}
              value={rest.values.siteEntityId}
              onChange={handleFormUpdate}
            >
              <MenuItem value="Select Entity Id" disabled>
                Select Entity
              </MenuItem>

              {entities.map((entity, index) => (
                <MenuItem value={entity._id} key={index}>
                  {entity.name}
                </MenuItem>
              ))}
            </TextField>

            <InputLabel htmlFor="institutionName" style={marginStyles}>
              Institution Name <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="institutionName"
              variant="outlined"
              label="Institution Name"
              style={{ width: '70%' }}
              value={rest.values.institutionName}
              onChange={handleFormUpdate}
              helperText={errors.institutionName}
              error={Boolean(errors.institutionName)}
            />
            {console.log(rest.values)}
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps('sitebleedSubscription')}
                    // helperText={touched.sitebleedSubscription && errors.sitebleedSubscription}
                    // error={Boolean(touched.sitebleedSubscription && errors.sitebleedSubscription)}
                    checked={!!rest.values.sitebleedSubscription}
                    onChange={handleFormUpdate}
                    name="sitebleedSubscription"
                  />
                }
                label="Enable bleed subscription"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps('sitebleedSubscription')}
                    // helperText={touched.sitebleedSubscription && errors.sitebleedSubscription}
                    // error={Boolean(touched.sitebleedSubscription && errors.sitebleedSubscription)}
                    checked={!!rest.values.siteOrthoSubscription}
                    onChange={handleFormUpdate}
                    name="siteOrthoSubscription"
                  />
                }
                label="Enable Orthoshield subscription"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps('sitebleedSubscription')}
                    // helperText={touched.sitebleedSubscription && errors.sitebleedSubscription}
                    // error={Boolean(touched.sitebleedSubscription && errors.sitebleedSubscription)}
                    checked={!!rest.values.siteOncoSubscription}
                    onChange={handleFormUpdate}
                    name="siteOncoSubscription"
                  />
                }
                label="Enable Oncoshield subscription"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps('sitebleedSubscription')}
                    // helperText={touched.sitebleedSubscription && errors.sitebleedSubscription}
                    // error={Boolean(touched.sitebleedSubscription && errors.sitebleedSubscription)}
                    checked={!!rest.values.siteVivoSubscription}
                    onChange={handleFormUpdate}
                    name="siteVivoSubscription"
                  />
                }
                label="Enable Vivoshield subscription"
              />
            </Box>

            <Box mt={3}>
              <Button
                type="reset"
                variant="contained"
                disabled={!isFormModified}
                color="primary"
                style={{ marginRight: '20px' }}
                onClick={() => {
                  resetForm();
                }}
              >
                {' '}
                Reset{' '}
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {props.btn}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Formik>
  );
};

export default SiteForm;
