import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// components
import NewLogo from '../components/NewLogo';
//
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3, 3, 3, 2.5),
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(5, 5, 0, 5)
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(5, 7, 0, 7)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <NewLogo sx={{ width: 360, height: 185, paddingLeft: 10 }} />
      </RouterLink>

      <MHidden width="smDown">
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant="h2">{children}</Typography>
        </Box>
      </MHidden>
    </HeaderStyle>
  );
}
