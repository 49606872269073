const { makeStyles } = require("@material-ui/styles")

export function getModalStyle() {
  const top = 33;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(${left}%, ${top}%)`,
  };
}
export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const iconStyle = {
  backgroundColor: '#dcf2d8',
  color: '#4fa52e',
  margin: '0px 3px',
  padding: '1px 1px',
  cursor: 'pointer'
}
