import React from 'react';

import { Box, Button, Modal, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useChangePassword } from './hooks';
import { modalStyle, textStyle, useStyles } from './styles';

const schemaValidation = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Min 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      '1 special char, 1 UpperCase, 1 number '
    )
    .required('password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('password is required')
});

const ChagePassword = () => {
  const { handleModal, modalOpen, formValues, handleFormUpdate, handleSavePassword } =
    useChangePassword();
  const Classes = useStyles();

  return (
    <>
      <Button
        style={{ margin: '10px 5px 0px 5px' }}
        variant="contained"
        color="primary"
        onClick={handleModal}
      >
        Change Password
      </Button>

      <Modal open={modalOpen} onClose={handleModal}>
        <div style={modalStyle} className={Classes.paper}>
          <CloseIcon className={Classes.closeBtn} onClick={handleModal} />
          <Formik
            validationSchema={schemaValidation}
            enableReinitialize
            initialValues={{
              password: formValues?.password || '',
              confirmPassword: formValues?.confirmPassword || ''
            }}
            onSubmit={handleSavePassword}
          >
            {({ handleSubmit, errors, ...rest }) => (
              <>
                <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
                  <Box my={1}>
                    <h3 style={{ margin: '0 0 10px 5px' }}>Change Password</h3>

                    <TextField
                      variant="standard"
                      style={textStyle}
                      name="password"
                      fullWidth
                      label="new password"
                      value={rest.values.password}
                      onChange={handleFormUpdate}
                      helperText={errors.password}
                      error={Boolean(errors.password)}
                    />

                    <TextField
                      variant="standard"
                      style={textStyle}
                      name="confirmPassword"
                      fullWidth
                      label="confirm password"
                      value={rest.values.confirmPassword}
                      onChange={handleFormUpdate}
                      helperText={errors.confirmPassword}
                      error={Boolean(errors.confirmPassword)}
                    />

                    <div style={{ marginTop: '10px' }}>
                      <Button type="submit" fullWidth variant="contained">
                        Update
                      </Button>
                    </div>
                  </Box>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default ChagePassword;
