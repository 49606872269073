import { useRef } from 'react';
import {
  electronicDocuments,
  endorsements,
  prohibitedUses,
  quickAccess,
  warranties
} from './constant';

export const useTC = () => {
  const sections = {
    0: { ref: useRef(null) },
    1: { ref: useRef(null) },
    2: { ref: useRef(null) },
    3: { ref: useRef(null) },
    4: { ref: useRef(null) },
    5: { ref: useRef(null) },
    6: { ref: useRef(null) },
    7: { ref: useRef(null) },
    8: { ref: useRef(null) },
    9: { ref: useRef(null) },
    10: { ref: useRef(null) },
    11: { ref: useRef(null) },
    12: { ref: useRef(null) },
    13: { ref: useRef(null) },
    14: { ref: useRef(null) },
    15: { ref: useRef(null) },
    16: { ref: useRef(null) },
    17: { ref: useRef(null) },
    18: { ref: useRef(null) },
    19: { ref: useRef(null) }
  };

  const executeScroll = (i) => {
    const { ref } = sections[i];
    ref.current.scrollIntoView();
  };

  return {
    sections,
    quickAccess,
    prohibitedUses,
    warranties,
    endorsements,
    electronicDocuments,
    executeScroll
  };
};
