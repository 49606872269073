/* eslint-disable no-nested-ternary */

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  TextField
} from '@material-ui/core';
// import { Image } from '@material-ui/icons';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import whatsappicon from '../../../assests/Images/whatsapp-svgrepo-com.png'
import InputPhone from '../../../components/country-code-input/index';
import ChangePassword from '../change-password';
import { userTitle } from '../constant';
import { useUserForm } from './hooks';

const marginStyles = {
  margin: '15px 0 10px 5px'
};
const UserForm = (props) => {
  const { handleUserForm, btn, isEdit, isDetail } = props;
  const {
    sites,
    roles,
    formValues,
    handleFormUpdate,
    handleFormArrayUpdate,
    setFormvalues,
    formField,
    resetForm,
    isFormModified,
    setIsFormModified,
    handleFormSubmit
  } = useUserForm(props);

  const getPhoneValidationSchema = (countryCode) => {
    const phoneRegexMap = {
      93: /^\d{7}$/, // Afghanistan
      355: /^\d{8}$/, // Albania
      213: /^\d{9}$/, // Algeria
      376: /^\d{6}$/, // Andorra
      244: /^\d{9}$/, // Angola
      54: /^\d{10}$/, // Argentin
      '1-268': /^\d{7}$/, // AntiguaandBarbuda
      374: /^\d{8}$/, // Armenia
      297: /^\d{7}$/, // Aruba
      43: /^\d{6,9}$/, // Austria
      994: /^\d{15}$/, // Azerbaijan
      973: /^\d{8}$/, // Bahrain
      '1-242': /^\d{10}$/, // Bahamas
      880: /^\d{10}$/, // Bangladesh
      '1-246': /^\d{7}$/, // Barbados
      375: /^\d{9}$/, // Belarus
      32: /^\d{9,10}$/, // Belgium
      229: /^\d{8}$/, // Benin
      975: /^\d{8}$/, // Bhutan/
      591: /^\d{7,8}$/, // Bolivia
      387: /^\d{8}$/, // BosniaandHerzegovina|| false
      267: /^\d{7}$/, // Botswana
      55: /^\d{8,9}$/, // Brazil
      246: /^\d{6}$/, // BritishIndianOceanTerritory
      673: /^\d{7}$/, // Brunei
      359: /^\d{10}$/, // Bulgaria
      226: /^\d{7}$/, // BurkinaFaso
      257: /^\d{7}$/, // Burundi
      855: /^\d{10}$/, // Cambodia
      237: /^\d{8,9}$/, // Cameroon
      '1-647': /^\d{11}$/, // Canada
      238: /^\d{7}$/, // Cape erde
      599: /^\d{7}$/, // CaribbeanNetherlands
      236: /^\d{8}$/, // CentralAfrica
      235: /^\d{6}$/, // Chad
      56: /^\d{8,9}$/, // Chile
      86: /^\d{11}$/, // China
      57: /^\d{10}$/, // Colombia
      269: /^\d{7}$/, // Comoros
      242: /^\d{9}$/, // Congo
      506: /^\d{8}$/, // CostaRica
      225: /^\d{8}$/, // CotedIvoire(IvoryCoast)
      385: /^\d{9}$/, // Croatia
      53: /^\d{7}$/, // Cuba
      357: /^\d{8}$/, // Cyprus
      420: /^\d{9}$/, // CzechRepublic
      45: /^\d{8}$/, // Denmark
      253: /^\d{6}$/, // Djibouti
      '1-767': /^\d{7}$/, // Dominica
      '1-809': /^\d{7}$/, // DominicanRepublic
      593: /^\d{8,9}$/, // Ecuador
      20: /^\d{7,8}$/, // Egypt
      503: /^\d{7}$/, // ElSalvador
      240: /^\d{8}$/, // EquatorialGuinea
      291: /^\d{7}$/, // Eritrea
      372: /^\+372\d{7}$/, // Estonia
      251: /^\d{9}$/, // Ethiopia
      679: /^\d{7}$/, // Fiji
      358: /^\d{7}$/, // Finland
      33: /^\d{10}$/, // France
      594: /^\d{9}$/, // FrenchGuiana
      689: /^\d{6}$/, // FrenchPolynesia
      241: /^\d{8}$/, // Gabon
      220: /^\d{6}$/, // Gambia
      995: /^\d{8}$/, // Georgia
      49: /^\d{10}$/, // Germany
      233: /^\d{9}$/, // Ghana
      30: /^\d{10}$/, // Greece
      224: /^\d{8}$/, // Guinea
      502: /^\d{8}$/, // Guatemala
      245: /^\d{7}$/, // Guinea-Bissau
      592: /^\d{6,7}$/, // Guyana
      509: /^\d{8}$/, // Haiti
      504: /^\d{8}$/, // Honduras
      852: /^\d{8}$/, // HongKong
      36: /^\d{8}$/, // Hungary
      354: /^\d{7}$/, // Iceland
      91: /^\d{10}$/, // India
      62: /^\d{8,12}$/, // Indonesia
      98: /^\d{10}$/, // Iran
      964: /^\d{6,7}$/, // Iraq
      353: /^\d{9}$/, // Ireland
      972: /^\d{9}$/, // Israel
      39: /^\+39\d{10}$/, // Italy
      7: /^\d{10,11}$/, // Kazakhstan
      254: /^\d{10}$/, // Kenya
      686: /^\d{7}$/, // Kiribati
      383: /^\d{9}$/, // Kosovo
      965: /^\d{8}$/, // Kuwait
      996: /^\d{9}$/, // Kyrgyzstan
      856: /^\d{9}$/, // Laos
      371: /^\+371\d{8}$/, // Latvia
      961: /^\d{7,8}$/, // Lebanon
      266: /^\d{8}$/, // Lesotho
      231: /^\d{7}$/, // Liberia
      218: /^\d{9}$/, // Libya
      423: /^\d{7}$/, // Liechtenstein
      370: /^\d{8}$/, // Lithuania
      352: /^\d{8}$/, // Luxembourg
      853: /^\d{8}$/, // Macau
      389: /^\d{8}$/, // NorthMacedonia
      261: /^\d{8}$/, // Madagascar
      265: /^\d{8}$/, // Malawi
      60: /^\d{10}$/, // Malaysia
      960: /^\d{15}$/, // Maldives
      223: /^\d{8}$/, // Mali
      692: /^\d{7}$/, // MarshallIslands
      596: /^\d{9}$/, // Martinique
      230: /^\d{7}$/, // Mauritius
      52: /^\d{10}$/, // Mexico
      691: /^\d{7}$/, // Micronesia
      373: /^\d{8}$/, // Moldova
      377: /^\d{8}$/, // Monaco
      976: /^\d{8}$/, // Mongolia
      382: /^\d{9}$/, // Montenegro
      212: /^\d{8}$/, // Morocco
      258: /^\d{9}$/, // Mozambique
      95: /^\d{8}$/, // Myanmar
      264: /^\d{8}$/, // Namibia
      674: /^\d{7}$/, // Nauru
      977: /^\d{10}$/, // Nepal
      31: /^\d{9}$/, // Netherlands
      687: /^\d{6}$/, // NewCaledonia
      64: /^\d{10}$/, // NewZealand
      505: /^\d{8}$/, // Nicaragua
      227: /^\d{8}$/, // Niger
      850: /^\d{8}$/, // NorthKorea
      47: /^\d{8}$/, // Norway
      968: /^\d{8}$/, // Oman
      92: /^\d{10}$/, // Pakistan
      680: /^\d{7}$/, // Palau
      970: /^\d{8}$/, // Palestine
      507: /^\d{8}$/, // Panama
      675: /^\d{7}$/, // PapuaNewGuinea
      595: /^\d{9}$/, // Paraguay
      51: /^\d{9}$/, // Peru
      63: /^\d{10}$/, // Philippines
      48: /^\d{9}$/, // Poland
      351: /^\d{9}$/, // Portugal
      '1-787': /^\d{7}$/, // PuertoRico
      974: /^\d{7}$/, // Qatar
      262: /^\d{9}$/, // Reunion
      40: /^\d{9}$/, // Romania
      '1-869': /^\d{7}$/, // SaintKittsandNevis
      '1-758': /^\d{7}$/, // SaintLucia
      '1-784': /^\d{7}$/, // SaintVincenandtheGrenadines
      685: /^\d{5}$/, // Samoa
      378: /^\d{6}$/, // Sanmarino
      966: /^\d{9}$/, // SaudiArabia
      221: /^\d{9}$/, // Senegal
      381: /^\d{8}$/, // Serbia
      248: /^\d{7}$/, // Seychelles
      232: /^\d{7}$/, // SierraLeone
      65: /^\d{8}$/, // Singapore
      421: /^\d{9}$/, // Slovakia
      386: /^\d{8}$/, // Slovenia
      677: /^\d{7}$/, // Solomonslands
      27: /^\d{9}$/, // SouthAfrica
      82: /^\d{9,11}$/, // South/Korea
      211: /^\d{9}$/, // SouthSudan
      34: /^\d{9}$/, // Spain
      94: /^\d{10}$/, // SriLanka
      249: /^\d{9}$/, // Sudan
      597: /^\d{7}$/, // Suriname
      268: /^\d{7}$/, // Eswatini
      46: /^\d{9,10}$/, // Sweden
      41: /^\d{9,10}$/, // Switzerland
      963: /^\d{9}$/, // Syria
      886: /^\d{9}$/, // Taiwan
      992: /^\d{9}$/, // Tajikistan
      255: /^\d{9}$/, // Tanzania
      66: /^\d{10}$/, // Thailand
      670: /^\d{6}$/, // Timor-Leste
      228: /^\d{8}$/, // Togo
      676: /^\d{7}$/, // Tonga
      '1-868': /^\d{7}$/, // TrinidadandTobago
      216: /^\d{8}$/, // Tunisia
      90: /^\d{9}$/, // Turkey
      993: /^\d{8}$/, // Turkmenistan
      688: /^\d{5}$/, // Tuvalu
      256: /^\d{9}$/, // Uganda
      380: /^\d{9}$/, // Ukraine
      971: /^\d{9}$/, // UnitedArabEmirates
      598: /^\d{8}$/, // Uruguay
      998: /^\d{9}$/, // Uzbekistan
      678: /^\d{5}$/, // Vanuatu
      379: /^\d{8}$/, // VaticanCity
      58: /^\d{10}$/, // Venezuela
      84: /^\d{9}$/, // Vietnam
      967: /^\d{9}$/, // Yemen
      260: /^\d{9}$/, // Zambia
      263: /^\d{9}$/ // Zimbabwe
    };
    const phoneRegex = phoneRegexMap[countryCode];

    return Yup.string()
      .matches(phoneRegex, 'Invalid phone number for the selected country code')
      .required('Phone Number Required');
  };

  const userFormSchema = Yup.object().shape({
    username: Yup.string().required('User Name required'),
    password: Yup.string().when('isEdit', {
      is: (val) => !val,
      then: Yup.string()
        .min(8, 'Must contain at least 8 characters')
        .max(15, 'Must not exceed 15 characters')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and one special case Character'
        )
        .required('Password Required')
    }),
    confirmPassword: Yup.string().when('$isEdit', {
      is: (val) => !val,
      then: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    email: Yup.string().email('Invalid email').required('Required'),
    role: Yup.string().required('Required field'),
    phone: Yup.lazy((value, { parent }) => getPhoneValidationSchema(parent.country_code)),
    country_code: Yup.string()
      .matches(/^\d{1,4}$/, 'Country code must be 1 to 4 digits')
      .required('Country Code Required'),
    isEdit: Yup.boolean()
  });

  return (
    <Formik
      validationSchema={userFormSchema}
      enableReinitialize
      initialValues={{
        username: formValues?.username || '',
        password: formValues?.password || '',
        email: formValues?.email || '',
        confirmPassword: formValues?.confirmPassword || '',
        firstname: formValues?.firstname || '',
        lastname: formValues?.lastname || '',
        country_code: formValues?.country_code || '91',
        phone: formValues?.phone || '',
        isWhatsAppOptIn: formValues?.isWhatsAppOptIn,
        sites: formValues?.sites || [],
        address: formValues?.address || '',
        role: formValues?.role || '',
        secondary_email_1: formValues.secondary_email_1 || '',
        secondary_email_2: formValues.secondary_email_2 || '',
        isEdit
      }}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, handleChange, errors, ...rest }) => (
        <>
          <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Breadcrumbs aria-label="breadcrumb" my={3}>
                  <Link color="inherit" to="/dashboard/users">
                    Users
                  </Link>
                  <Link
                    color="textPrimary"
                    to={`/dashboard/users/${
                      isEdit ? 'edit-user' : isDetail ? 'user-report' : 'add-user'
                    }`}
                    aria-current="page"
                  >
                    {userTitle[isEdit ? 'edit' : isDetail ? 'details' : 'add']}
                  </Link>
                </Breadcrumbs>
              </div>
              {isEdit && (
                <div>
                  {/* <Button
                    style={{ margin: '10px 5px 0px 5px' }}
                    variant="contained"
                    color="primary"
                  >
                    Change State
                  </Button> */}
                  <ChangePassword />
                </div>
              )}
            </div>
            <Grid container spacing={2} style={{ width: '70%' }}>
              <Grid item sm={6} xs={12}>
                <InputLabel htmlFor="firstname" style={marginStyles}>
                  First Name <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  name="firstname"
                  variant="outlined"
                  label="First Name"
                  fullWidth
                  value={rest.values.firstname}
                  helperText={errors.firstname}
                  error={Boolean(errors.firstname)}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputLabel htmlFor="lastname" style={marginStyles}>
                  Last Name
                </InputLabel>
                <TextField
                  name="lastname"
                  variant="outlined"
                  label="Last Name"
                  fullWidth
                  value={rest.values.lastname}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ width: '70%' }}>
              <Grid item sm={6} xs={12}>
                <InputLabel htmlFor="email" style={marginStyles}>
                  Email <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  name="email"
                  variant="outlined"
                  label="Enter Email"
                  fullWidth
                  value={rest.values.email}
                  onChange={handleFormUpdate}
                  helperText={errors.email}
                  error={Boolean(errors.email)}
                  disabled={isDetail}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputLabel style={marginStyles}>Secondary Email 1</InputLabel>
                <TextField
                  name="secondary_email_1"
                  variant="outlined"
                  label="Enter Email"
                  fullWidth
                  value={rest.values.secondary_email_1}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputLabel style={marginStyles}>Secondary Email 2</InputLabel>
                <TextField
                  name="secondary_email_2"
                  variant="outlined"
                  label="Enter Email"
                  fullWidth
                  value={rest.values.secondary_email_2}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                />
              </Grid>

              <Grid mt={0} ml={0} container spacing={2} style={{ width: '50%' }}>
                <Grid item sm={6} xs={12}>
                  <InputLabel htmlFor="phone" style={marginStyles}>
                    Country Code<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <InputPhone
                    specialLabel=""
                    name="country_code"
                    value={rest.values.country_code}
                    onPhoneChange={(value) => {
                      handleFormUpdate({ target: { name: 'country_code', value } });
                    }}
                    helperText={errors.country_code}
                    error={Boolean(errors.country_code)}
                  />
                  {/* <InputLabel htmlFor="country_code" style={marginStyles}>
                    Country Code <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    name="country_code"
                    variant="outlined"
                    label="Country Code"
                    fullWidth
                    value={rest.values.country_code}
                    onChange={handleFormUpdate}
                    helperText={errors.country_code}
                    error={Boolean(errors.country_code)}
                    disabled={isDetail}
                  /> */}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel htmlFor="phone" style={marginStyles}>
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    name="phone"
                    variant="outlined"
                    label="Phone Number"
                    fullWidth
                    value={rest.values.phone}
                    onChange={handleFormUpdate}
                    disabled={isDetail}
                    helperText={errors.phone}
                    error={Boolean(errors.phone)}
                  />
                </Grid>
                {rest.values.phone ? (
                    <Box display="flex" gap="5px" paddingLeft="31px" paddingTop="10px" >
                      <Checkbox
                        size='small'
                        style={{marginTop:'-7px'}}
                        name="isWhatsAppOptIn"
                        aria-checked={rest.values.isWhatsAppOptIn}
                        checked={rest.values.isWhatsAppOptIn}
                        value={rest.values.isWhatsAppOptIn}
                        onChange={handleChange}
                        type="checkbox"
                        />
                      <InputLabel htmlFor="isWhatsAppOptIn" style={{ fontSize: '14px', display:'flex', gap:'5px' }}>
                        <div> Provided number is your</div> <img width='22px' height='22px' src={whatsappicon} alt="" /> <div> WhatsApp number</div>
                      </InputLabel>
                    </Box>
                  ) : null}
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ width: '70%' }}>
              <Grid item sm={12} xs={12}>
                <InputLabel htmlFor="username" style={marginStyles}>
                  Username <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  name="username"
                  variant="outlined"
                  label="Enter Username"
                  fullWidth
                  value={rest.values.username}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                  helperText={errors.username}
                  error={Boolean(errors.username)}
                />
              </Grid>
            </Grid>

            {!isEdit && !isDetail && (
              <>
                <Grid container spacing={2} style={{ width: '70%' }}>
                  <Grid item sm={12} xs={12}>
                    <InputLabel htmlFor="password" style={marginStyles}>
                      Password <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      type="password"
                      name="password"
                      variant="outlined"
                      label="Enter Password"
                      fullWidth
                      value={rest.values.password}
                      onChange={handleFormUpdate}
                      helperText={errors.password}
                      error={Boolean(errors.password)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ width: '70%' }}>
                  <Grid item sm={12} xs={12}>
                    <InputLabel htmlFor="confirmPassword" style={marginStyles}>
                      Confirm Password <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      type="password"
                      name="confirmPassword"
                      variant="outlined"
                      label="Re-Enter Password"
                      fullWidth
                      value={rest.values.confirmPassword}
                      onChange={handleFormUpdate}
                      helperText={errors.confirmPassword}
                      error={Boolean(errors.confirmPassword)}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container spacing={2} style={{ width: '70%' }}>
              <Grid item sm={12} xs={12}>
                <InputLabel htmlFor="sites" style={marginStyles}>
                  Sites
                </InputLabel>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    multiple
                    options={sites}
                    getOptionLabel={(site) => (site.name ? site.name : '')}
                    isOptionEqualToValue={(site, value) => site.name === value.name}
                    value={rest.values.sites}
                    disabled={isDetail}
                    onChange={(event, value) => {
                      handleFormArrayUpdate('sites', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="sites"
                        placeholder="Select Sites"
                        label="Select Sites"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ width: '70%' }}>
              <Grid item sm={12} xs={12}>
                <InputLabel htmlFor="role" style={marginStyles}>
                  Role <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  select
                  name="role"
                  variant="outlined"
                  label="Select Role"
                  fullWidth
                  helperText={errors.role}
                  error={Boolean(errors.role)}
                  value={rest.values.role}
                  onChange={handleFormUpdate}
                  disabled={isDetail}
                >
                  <MenuItem value="Select Role" disabled>
                    Select Role
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role._id} value={role._id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {isDetail ? null : (
              <Box mt={3}>
                <Button
                  type="reset"
                  variant="contained"
                  disabled={!isFormModified}
                  color="primary"
                  style={{ marginRight: '20px' }}
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {btn}
                </Button>
              </Box>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

export default UserForm;

UserForm.propTypes = {
  handleUserForm: PropTypes.func,
  btn: PropTypes.string,
  isEdit: PropTypes.bool,
  isDetail: PropTypes.bool
};
