import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useAddSite = () => {
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const { mutate: handleAddSite,isSuccess:handleSuccess } = useMutation(
    (payload) => api().post(apiEndpoints.sites, payload),
    {
      onSuccess: (response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.msg
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error.response.data.msg
          })
        );
      }
    }
  );

  return { handleAddSite ,handleSuccess};
};
