import styled from '@emotion/styled';
import { OutlinedInput, Toolbar } from '@material-ui/core';

export const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 120,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

export const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '20%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '22%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
