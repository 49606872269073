import { makeStyles } from '@material-ui/styles';

export const modalStyle = {
  top: `${33}%`,
  left: `${30}%`,
  transform: `translate(${30}%, ${33}%)`,
  border: 'none'
};

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
