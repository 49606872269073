import React from 'react';

import { Box, Button, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

import { modalStyle, useStyles } from './styles';

const DeleteConfirmation = ({ openModal, message, handleDelete, handleModal }) => {
  const classes = useStyles();
  return (
    <Modal open={openModal} onClose={handleModal}>
      <div style={modalStyle} className={classes.paper}>
        <Box my={3}>
          <p>{message}</p>
        </Box>
        <Button variant="contained" onClick={handleDelete} style={{ marginRight: '10px' }}>
          Delete
        </Button>
        <Button variant="contained" onClick={handleModal}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;

DeleteConfirmation.propTypes = {
  openModal: PropTypes.bool,
  message: PropTypes.string,
  handleDelete: PropTypes.func,
  handleModal: PropTypes.func
};
