import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from "formik";
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs, TextField, InputLabel, SnackbarContent, Snackbar } from '@material-ui/core';
import { fetchSingleEntities } from '../../../api/entityList/fetchSingleEnity';

const EntityDetails = () => {

  const marginStyles = {
    margin: '15px 0 10px 5px'
  }


  const [editUserForm, setEditUserForm] = useState({});
  const { id } = useParams();
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };


  useEffect(() => {
    const siteFetch = async () => {

      const res = await fetchSingleEntities(id)
      if (res) {
        if (res.status === 404) {
          setResponseState({ error: true, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.msg ==='Entity fetched successfully') {
          setEditUserForm(res.data);
        } else if (res.status===500) {
          setResponseState({ error: true, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    siteFetch();
  }, [id])



  const formik = useFormik({
    initialValues: {
      entityName: editUserForm.name,
      entityDescription: editUserForm.description,

    },
    enableReinitialize: true,
  })

  console.log("editUserform", editUserForm);

  const { handleSubmit } = formik;



  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' onSubmit={handleSubmit} >

        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: responseState.error ? 'red' : 'green' }} message={responseState.msg} />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/entities" >
            Entities
          </Link>
          <Link
            color="textPrimary"
            to="/dashboard/entities/details/"
            aria-current="page"
          >
           Entity Details
          </Link>
        </Breadcrumbs>


        <InputLabel htmlFor="entityName" style={marginStyles}>
          Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
        disabled
          name="entityName"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.name}
        />

        <InputLabel htmlFor="entityDescription" style={marginStyles}>
          Institution Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
        disabled
          name="entityDescription"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.description}
        />


      </Form>
    </FormikProvider>
  )
};

export default EntityDetails;
