import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

export const useForgotPassword = () => {
  const useStyles = makeStyles({
    form: {
      margin: 'auto',
      // width: '450px',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // height: '90%'
    },
    mainHeading: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center'
    },
    formBody: {
      width:'90%',
      margin:'auto',
      // marginTop: '15%',
      // padding: '30px 20px 20px',
      backgroundColor: '#fff',
      // border: '2px solid white',
      // boxShadow: '0 0 2px 2px lightgrey'
    },
    H2: {
      // margin: '10px 22%'
      display: 'flex',
      justifyContent: 'center'
    },
    para: {
      textAlign:'center',
      color:'gray',
      fontSize:'13px',
      paddingBottom:'7px'
      // margin: '10px',
      // padding: '5px'
    },
    input: {
      margin: '10px 0'
    },
    btn: {
      // margin: '5% auto',
      padding: '10px 20px',
      display: 'flex',
      justifyContent: 'center'
    },
    back: {
      // marginLeft: '15px'
      // margin: '0 auto',
      paddingTop:'20px'
      // display: 'flex',
      // justifyContent: 'center'
    }
  });

  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const classes = useStyles();

  const mailValidation = Yup.object().shape({
    username: Yup.string().required('Username required'),
    email: Yup.string().email('Enter valid Email').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: ''
    },
    validationSchema: mailValidation,
    onSubmit: (value) => {
      axios
        .post(`${process.env.REACT_APP_API_SERVER_URL}/api/users/reset-password-mail`, value)
        .then((res) => {
          console.log(res,'resof fa');
          setResponseState({ error: false, msg: res.data?.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          if(res.data.status===false){
            setResponseState({ error: true, msg: res?.data?.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });

          }
        })
        .catch((error) => {
          setResponseState({ error: true, msg: "Invalid Username or Email kindly check"});
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        });
    }
  });

  return {
    responseState,
    classes,
    formik,
    handleClose,
    vertical,
    horizontal,
    open
  };
};
