// scroll bar
import 'simplebar/src/simplebar.css';

import jwt from 'jwt-decode';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useNavigate } from 'react-router-dom';

// redux
import axios from 'axios';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import store from './redux/store';

//
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// ----------------------------------------------------------------------

import { setLoggedInUser, setLogoutStatus } from './redux/actions/authActions';
import setAuthorizationToken from './utils/setAuthorizationToken';
import SnackBar from './components/snackbar';

const currentUserApi = async () => {
  axios
    .get(`${process.env.REACT_APP_API_SERVER_URL}/api/users/currentUser/data`)
    .then((res) => {
      const { passcode } = JSON.parse(localStorage.loggedInUser) || {};
      localStorage.setItem('loggedInUser', JSON.stringify({ ...res.data, passcode }));

      const { data: { sites = [] } = {} } = res.data;

      const hasEnabledSitebleedSubscription = sites.find((site) => !!site.sitebleedSubscription);

      store.dispatch(
        setLoggedInUser({
          ...res.data,
          data: { ...res.data.data, hasBleedSubscription: !!hasEnabledSitebleedSubscription }
        })
      );
    })
    .catch((err) => {
      localStorage.removeItem('loggedInUser');
      store.dispatch(setLogoutStatus());
    });
};

if (localStorage.loggedInUser) {
  const isTokenExpired = jwt(JSON.parse(localStorage.loggedInUser).token);
  const currentDate = new Date();

  if (isTokenExpired.exp * 1000 < currentDate.getTime()) {
    setAuthorizationToken();
    localStorage.removeItem('loggedInUser');
    store.dispatch(setLogoutStatus());
    window.location = '/login'
    
  } else {
    setAuthorizationToken(JSON.parse(localStorage.loggedInUser).token);
    store.dispatch(setLoggedInUser(JSON.parse(localStorage.loggedInUser)));
    currentUserApi();
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <SnackBar />
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
