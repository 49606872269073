import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useDeleteSite = ({ id, callback }) => {
  const [openModal, setOpenModal] = useState(false);
  const { api, apiEndpoints } = useApi();
  const dispatch = useDispatch();
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const { mutate: handleDeleteSite } = useMutation(
    () => api().delete(apiEndpoints.singleSite(id)),
    {
      onSuccess: (data) => {
        handleModal();
        callback();
        dispatch(
          showSnackbar({
            type: 'success',
            message: data.data.message
          })
        );
      }
    }
  );

  return { openModal, handleModal, handleDeleteSite };
};
