import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import DeleteConfirmation from '../../../components/delete-confirmation';
import { iconStyle } from '../../../constants';
import { useDeleteUser } from './hooks';

const DeleteUser = ({ id, refetchUsers }) => {
  const { openModal, handleModal, handleDeleteUser } = useDeleteUser({
    id,
    callback: () => {
      handleModal();
      refetchUsers();
    }
  });

  return (
    <>
      <Tooltip title="Delete" arrow>
        <Delete style={iconStyle} onClick={handleModal} />
      </Tooltip>
      <DeleteConfirmation
        handleDelete={handleDeleteUser}
        handleModal={handleModal}
        openModal={openModal}
        message="Do You want to Delete this user permanently ?"
      />
    </>
  );
};

export default DeleteUser;
