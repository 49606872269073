import React from 'react';

import SiteForm from '../form';
import { useEditSite } from './hooks';

const EditUser = () => {
  const { handleEditSite, selectedSite } = useEditSite();
  return (
    <SiteForm handleSiteForm={handleEditSite} btn="Update" fieldValues={selectedSite} isEdit />
  );
};

export default EditUser;
