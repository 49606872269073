import axios from 'axios';


export const fetchSite = (siteId) =>(

    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/sites/${siteId}`)
    .catch((error)=>(error))

)

