import axios from 'axios';

import { AUTH_HEADER_NAME, BASE_URL } from '../../constants';
import { getBearerToken } from '../../utils/setAuthorizationToken';
import apiEndpoints from './api-endpoints';
import store from '../../redux/store';
import { setLogoutStatus } from '../../redux/actions/authActions';

export const useApi = () => {
  const api = (headers = {}, configs = {}) => {
    const apiHeader = {
      'Content-Type': 'application/json',
      ...headers
    };
    const apiConfig = axios.create({
      baseURL: BASE_URL,
      headers: apiHeader,
      ...configs
    });

    apiConfig.interceptors.request.use(async (config) => {
      const authToken = await getBearerToken();

      if (authToken) {
        config.headers[`${AUTH_HEADER_NAME}`] = authToken;
      }
      return config;
    });

    apiConfig.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          localStorage.removeItem('loggedInUser');
          store.dispatch(setLogoutStatus());
        }

        throw error;
      }
    );
    return apiConfig;
  };

  return { api, apiEndpoints };
};
