export const TABLE_HEAD = [
  { id: 'UUID', label: 'NSID', alignRight: false },
  { id: 'patientId', label: 'Patient ID', alignRight: false },
  { id: 'patientName', label: 'Patient Name', alignRight: false },
  { id: 'patientGender', label: 'Gender', alignRight: false },
  { id: 'patientAge', label: 'Age', alignRight: false },
  { id: 'processingStatus', label: 'Current Status', alignRight: false },
  // { id: 'source', label: 'Upload Source', alignRight: false },
  { id: 'uploadedDate', label: 'Uploaded Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

export const TABLE_HEAD_NEW = [
  { id: 'UUID', label: 'NSID', alignRight: false },
  { id: 'patientId', label: 'Patient ID', alignRight: false },
  { id: 'patientGender', label: 'Gender', alignRight: false },
  { id: 'patientAge', label: 'Age', alignRight: false },
  { id: 'site', label: 'Site', alignRight: false },
  { id: 'processingStatus', label: 'Current Status', alignRight: false },
  { id: 'condition', label: 'Report Required', alignRight: false },
  { id: 'uploadedDate', label: 'Uploaded Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

export const studyProcessed = [
  'Complete',
  'Completed',
  'Complete 7 S',
  'Complete 11 S',
  'Complete 13 S',
  'Complete 21 S',
  'Complete 30 S',
  'In Review'
];

export const completeStatus = ['Complete 7 S', 'Complete 11 S', 'Complete 13 S', 'Complete 21 S'];

export const shortReportProcessingStatus = [
  'Complete',
  'Completed',
  'Complete 7 S',
  'Complete 11 S',
  'Complete 13 S',
  'Complete 21 S',
  'Complete 30 S'
];

export const studyMarkedComplete = [
  'Complete',
  'Completed',
  'Complete 30 S',
]
