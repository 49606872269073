import React, { useState } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  Box,
  Button,
  Modal,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useApi } from '../../../../config/api';
import { showSnackbar } from '../../../../components/snackbar/actions';

const siteSchema = Yup.object().shape({
  patientAge: Yup.string().required('Required'),
  patientSex: Yup.string().required('Required')
});

function getModalStyle() {
  const top = 33;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(${left}%, ${top}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const UpdateStudy = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const { id } = useParams();
  const location = useLocation();
  const { siteId } = location.state;

  const [modalStyle] = useState(getModalStyle);
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const { api, apiEndpoints } = useApi();
  const dispatch = useDispatch();
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const Classes = useStyles();

  const { data: { data: { data: study } = {} } = {}, refetch: fetchStudy } = useQuery(
    ['studtDetails', id],
    () => api().get(apiEndpoints.singleStudy(id))
  );

  const { mutate: markComplete } = useMutation(
    () => api().get(apiEndpoints.markStudyCancel(id, siteId), { params: { reason: cancelReason } }),
    {
      onSuccess: (response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.message
          })
        );
        fetchStudy();
      }
    }
  );

  const { mutate: updateStudy } = useMutation(
    (values) => api().patch(apiEndpoints.singleStudy(id), values),
    {
      onSuccess: () => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: 'Updated Successfully!'
          })
        );
      },
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err?.message || err?.data?.message
          })
        );
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      patientAge: study?.age || '',
      patientSex: study?.gender || 'Male'
    },
    enableReinitialize: true,
    validationSchema: siteSchema,
    onSubmit: updateStudy
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/worklist">
            WorkList
          </Link>
          <Link color="textPrimary" to="/dashboard/worklist" aria-current="page">
            Edit Study
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="patientAge" style={marginStyles}>
          Age <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          type="number"
          name="patientAge"
          variant="outlined"
          label="Patient Age"
          fullWidth
          {...getFieldProps('patientAge')}
          style={{ width: '70%' }}
          helperText={touched.patientAge && errors.patientAge}
          error={Boolean(touched.patientAge && errors.patientAge)}
        />
        <Box mt={2}>
          <FormControl
            component="fieldset"
            error={Boolean(touched.patientSex && errors.patientSex)}
          >
            <FormLabel component="legend">
              Gender <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-label="patientSex"
              name="patientSex"
              {...getFieldProps('patientSex')}
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
            <FormHelperText error={Boolean(touched.patientSex && errors.patientSex)}>
              {Boolean(touched.patientSex && errors.patientSex) && 'Required'}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mt={3}>
          <Button type="reset" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Update{' '}
          </Button>
        </Box>

        {study?.status === 'Cancelled' ? null : (
          <>
            <Box mt={3}>
              <Button variant="contained" color="error" onClick={handleOpen}>
                Mark as Cancelled
              </Button>
            </Box>

            <InputLabel htmlFor="cancelReason" style={marginStyles}>
              Reason to Cancel
            </InputLabel>
            <TextField
              type="text"
              name="cancelReason"
              variant="outlined"
              label="Enter a reason"
              fullWidth
              style={{ width: '70%' }}
              value={cancelReason}
              onChange={handleCancelReasonChange}
            />
          </>
        )}

        <Modal open={modalOpen} onClose={handleModalClose}>
          <div style={modalStyle} className={Classes.paper}>
            <Box my={3}>
              <p>Do You want to Mark status as cancelled?</p>
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                markComplete();
                setModalOpen(false);
              }}
              style={{ marginRight: '10px' }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {' '}
              Cancel{' '}
            </Button>
          </div>
        </Modal>
      </Form>
    </FormikProvider>
  );
};

export default UpdateStudy;
