import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';
import { useStyles } from './styles';


export const useEntityList=()=>{
  const [selected, setSelected] = useState([]);
  const [searchedEntity] = useState('');
  const [filterEntityName, setFilterEntityName] = useState('');
  const { api, apiEndpoints } = useApi();
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    entityname: '',
    sort:{name:1},
    order: 'desc',
    orderBy: 'createdAt'
  });
  const Classes = useStyles();
  const dispatch = useDispatch();

  const handleFilterByEntityName = (e) => {
    setParams((prev) => ({ ...prev, [e.target.name]: e.target.value, offset: 0 }));
  }

const {
  data: { data: { data: entities = [], total_count: total= 0 } = {} } = {},
  isLoading:entitiesLoading
}=useQuery(['entities',params],
()=>api().get(apiEndpoints.entities,{params
}),
    {
      onSuccess: () => { },
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
)

  const handleRequestSort = (event, property) => {
    if (property === 'action' || property === 'sites') return;
    setParams((prev) => {
      // Calculate the new order and orderBy values
      let newOrder = 'asc';
      if (prev.orderBy === property && prev.order === 'asc') {
        // eslint-disable-next-line no-unused-vars
        newOrder = 'desc';
      }
      return {
        ...prev,
        order: newOrder,
        orderBy: property,
        sort: newOrder === 'asc' ? property : `-${property}`
      };
    });
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = entities.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (patientId) => {
    const selectedIndex = selected.indexOf(patientId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, patientId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * params.limit;
    setParams((prev) => ({ ...prev, offset: newOffset }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({ ...prev, limit: parseInt(event.target.value, 10), offset: 0 }));
  };

  const refreshPage = () => setParams((prev) => ({ ...prev, limit: 10, offset: 0 }));


  return {
    Classes,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByEntityName,
    handleClick,
    handleRequestSort,
    handleSelectAllClick,
    entities,
    searchedEntity,
    selected,
    params,
    total,
    entitiesLoading,
    filterEntityName,
    refreshPage

  }
}
