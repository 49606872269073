

import UserForm from '../form';
import { useAddUser } from './hooks';

const AddUser = () => {
  const { handleAddUser, handleSuccess } = useAddUser();
  return <UserForm handleUserForm={handleAddUser} btn="Create" handleSuccess={handleSuccess} />;
};

export default AddUser;
