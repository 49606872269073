import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/authentication/login/Login';
import Register from './pages/authentication/register/Register';
import CreateEntity from './pages/entities/CreateEntity';
import EntityList from './pages/entities/entityList/index';
import NotFound from './pages/error/Page404';
import Profile from './pages/profile/Profile';
import ForgotPassword from './pages/resetPassword/forgot-password/ForgotPassword';
import SiteStudy from './pages/siteList/add-site';
import EditSite from './pages/siteList/edit-site';
import SiteDetails from './pages/siteList/SiteDetails';
import SiteList from './pages/siteList/site-list';
import AddUser from './pages/user/add-user';
import EditUser from './pages/user/edit-user';
import UserDetails from './pages/user/user-details';
import UserList from './pages/user/user-list';
import UploadSegmentation from './pages/workList/uploadSegmentation/UploadSegmentation';
import UploadStudy from './pages/workList/uploadStudy/UploadStudy';
import WorkList from './pages/workList/worklist/WorkList';
import WorklistReport from './pages/workList/worklist/WorklistReport';
// import EditEntity from './pages/entities/EditEntity';
import TermsAndConditions from './pages/legal/terms-and-conditions';
import ResetPassword from './pages/resetPassword/reset-password/ResetPassword';
import UpdateStudy from './pages/workList/worklist/update-worklist/UpdateStudy';
import SiteAccess from './pages/siteaccess/siteAccess';
import EditEntity from './pages/entities/editEntity/index';
import EntityDetails from './pages/entities/entity-details';
import ConfirmEmail from './pages/authentication/email-verification';
import RegionLogin from './pages/authentication/regionLogin';
import RegionRegister from './pages/authentication/regionRegister';

// ----------------------------------------------------------------------

export default function Router() {
  const isAuthenticated = useSelector((state) => state.loggedInUser.isAuthenticated);
  const hasBleedSubscription = useSelector((state) => state.loggedInUser.user.hasBleedSubscription);

  const { user } = useSelector((state) => state.loggedInUser);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isUser, setIsUser] = useState(true);

  useEffect(() => {
    if (user.role === 'Admin') {
      setIsAdmin(true);
      setIsUser(false);
    } else {
      setIsUser(true);
      setIsAdmin(false);
    }
  }, [user]);

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: !isAuthenticated ? (
            <Login />
          ) : (
            <Navigate to='/siteaccess'/>
          )
        },
        { path: 'confirmEmail', element: <ConfirmEmail /> },
        { path: 'region-register', element: <RegionRegister /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'upload', element: <UploadStudy /> },
        { path: '/', element: !isAuthenticated ? <RegionLogin /> : <Navigate to="/dashboard/app"/>},
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'siteaccess',
          element: isAuthenticated ? <SiteAccess /> : <Navigate to="/login" />
        }
      ]
    },
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <WorkList /> },
        { path: 'worklist', element: <WorkList /> },
        {
          path: 'sitelist',
          element: isAdmin ? <SiteList /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users',
          element: isAdmin ? <UserList /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/add-user',
          element: isAdmin ? <AddUser /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/edit/:id',
          element: isAdmin ? <EditUser /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/details/:id',
          element: isAdmin ? <UserDetails /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entities',
          element: isAdmin ? <EntityList /> : <Navigate to="/dashboard/app" />
        },
        // { path: 'entities/edit-entity/:id' , element: isAdmin ? <EditEntity /> : <Navigate to="/dashboard/app"  /> },
        {
          path: 'worklist/upload',
          element: isAuthenticated ? <UploadStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/upload-segmentation',
          element: isAdmin ? <UploadSegmentation /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/edit-study/:id',
          element: isAdmin || isUser ? <UpdateStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/report',
          element: isAdmin || isUser ? <WorklistReport /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/create-site',
          element: isAdmin ? <SiteStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/site-details',
          element: isAdmin ? <SiteDetails /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/edit-site/:id',
          element: isAdmin ? <EditSite /> : <Navigate to="/dashboard/app" />
        },

        {
          path: 'entities/create-entity',
          element: isAdmin ? <CreateEntity /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entitieslist/edit-entity/:id',
          element: isAdmin ? <EditEntity /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entities/details/:id',
          element: isAdmin ? <EntityDetails /> : <Navigate to="/dashboard/app" />
        },
        { path: '/profile', element: <Profile /> }
      ]
    },
    {
      path: '/legal',
      children: [{ path: '/terms-and-conditions', element: <TermsAndConditions /> }]
    },
    { path: '*', element: <Navigate to="/404" /> }
  ]);
}
