import { Box, Breadcrumbs, Button, InputLabel, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useEntityForm } from './hooks';

const marginStyles = {
  margin: '15px 0 10px 5px'
};
const entitySchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

const EntityForm = (props) => {
  const {
    entities,
    formValues,
    handleFormUpdate,
    handleFormSubmit,
    resetForm,
    isFormModified,
    formField,
    setFormvalues,
    fieldValues
  } = useEntityForm(props);

  return (
    <Formik
      validationSchema={entitySchema}
      enableReinitialize
      initialValues={{
        name: formValues?.name || '',
        description: formValues?.description || ''
      }}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, errors, ...rest }) => (
        <>
          <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {' '}
                <Breadcrumbs aria-label="breadcrumb" my={3}>
                  <Link color="inherit" to="/dashboard/entities">
                    Entities
                  </Link>
                  <Link
                    color="textPrimary"
                    to="/dashboard/entities/create-entity"
                    aria-current="page"
                  >
                    Edit Entity
                  </Link>
                </Breadcrumbs>
              </div>
            </div>
            {console.log('initialvalues-', rest.values.name, rest.values.description)}
            <InputLabel htmlFor="name" style={marginStyles}>
              {' '}
              Name <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="name"
              variant="outlined"
              label="Entity Name"
              style={{ width: '70%' }}
              value={rest.values.name}
              onChange={handleFormUpdate}
              helperText={errors.name}
              error={Boolean(errors.name)}
            />

            <InputLabel htmlFor="description" style={marginStyles}>
              Description
            </InputLabel>
            <TextField
              name="description"
              variant="outlined"
              label="Entity Description"
              style={{ width: '70%' }}
              value={rest.values.description}
              onChange={handleFormUpdate}
            />

            <Box mt={3}>
              <Button
                disabled={!isFormModified}
                type="reset"
                variant="contained"
                color="primary"
                style={{ marginRight: '20px' }}
                onClick={resetForm}
              >
                {' '}
                Reset{' '}
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {' '}
                {props.btn}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Formik>
  );
};
export default EntityForm;
