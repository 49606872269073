export const iconStyle = {
  backgroundColor: '#dcf2d8',
  color: '#4fa52e',
  margin: '0px 3px',
  padding: '1px 1px',
  cursor: 'pointer'
};

export const BASE_URL = process.env.REACT_APP_API_SERVER_URL;
export const AUTH_HEADER_NAME = 'Authorization';
