import axios from 'axios';

const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.Authorization = token;
  } else {
    delete axios.defaults.headers.Authorization;
  }
};

export default setAuthorizationToken;

export const getBearerToken = () => {
  const user = localStorage.getItem('loggedInUser');
  if (user) {
    return JSON.parse(user).token;
  }
};
