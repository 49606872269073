import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { setLoggedInUser } from '../../../redux/actions/authActions';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';
// material

export const useLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const usernameParam = queryParams.get('username');

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [counter, setCounter] = useState(0);

  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      username: usernameParam || '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (value) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: value.username,
            password: value.password
          })
        });
        const resJson = await res.json();

        if (res.status === 200) {
          setCounter(0);

          const loggedInUser = resJson;
          const { data: { sites = [] } = {} } = loggedInUser;

          const hasEnabledSitebleedSubscription = sites.find((site) => !!site.sitebleedSubscription);

          setAuthorizationToken(resJson.token);

           localStorage.setItem(
             'loggedInUser',
             JSON.stringify({
               ...loggedInUser,
               data: {
                 ...loggedInUser.data,
                 hasBleedSubscription: !!hasEnabledSitebleedSubscription
               }
             })
           );

          setResponseState({ success: true, msg: resJson.data.msg });

          dispatch(
            setLoggedInUser({
              ...loggedInUser,
              data: { ...loggedInUser.data, hasBleedSubscription: !!hasEnabledSitebleedSubscription }
            })
          );

          // eslint-disable-next-line no-extra-boolean-cast
          if (!!hasEnabledSitebleedSubscription) {
            navigate('/siteaccess', { replace: true });
          } else {
            navigate('/dashboard/app', { replace: true });
          }
        }

        if (res.status === 401) {
          setResponseState({ error: true, msg: 'Invalid Credentials' });

          setState({ open: true, vertical: 'top', horizontal: 'right' });
          setCounter(resJson.data.failedLoginAttempts);
          setTimeout(() => {
            setResponseState({ error: false, msg: '' });
          }, 3000);
        }
        if(res.status===403){
          console.log(resJson)
          setResponseState({ error: true, msg: resJson.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });

        }
      } catch (err) {
        setResponseState({ error: true, msg: err.data.msg });

        setState({ open: true, vertical: 'top', horizontal: 'right' });

        setTimeout(() => {
          setResponseState({ error: false, msg: '' });
        }, 3000);
      }
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return {
    usernameParam,
    showPassword,
    responseState,
    vertical,
    horizontal,
    open,
    handleClose,
    formik,
    handleShowPassword,
    counter
  };
};
