/* eslint-disable no-unreachable */
import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import bgImage from '../../../assests/Images/login-image.png';
import neuroShield from '../../../assests/Images/neuroshiiled-logo-2-3.jpg';
import { LoginForm } from './index';
import backImage from '../../../assests/Images/MicrosoftTeams-image (1).png';
import neurostudio from '../../../assests/Images/INMED AI STUDIO final-01 1.png';
import leftBackImg from '../../../assests/Images/MicrosoftTeams-image (2).png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';
import inmedLogo from '../../../assests/Images/inmed-logo.png';
import { useLogin } from './hooks';

const HeaderGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3.5, 7, 3.5, 7),
  maxHeight: '180px',
  boxShadow: '0 20px 20px rgba(145, 158, 171, 0.32)',
  [theme.breakpoints.up('xs')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between'
  }
}));

const HeaderText = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: 40,
  width: '45%'
  // backgroundSize: 'contain',
  // backgroundRepeat: 'no-repeat',
  // [theme.breakpoints.up('xs')]: {
  //   justifyContent: 'center',
  //   // width:'10%',
  //   alignItems: 'center',
  //   padding: theme.spacing(10, 6, 10, 6)
  // },
  // [theme.breakpoints.up('md')]: {
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   padding: theme.spacing(0, 12, 0, 12),
  //   // backgroundImage: `url(${bgImage})`
  // },
  // [theme.breakpoints.up('lg')]: {
  //   padding: theme.spacing(0, 24, 0, 24)

  // }
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(3, 7, 3, 7),
  boxShadow: '0 -20px 20px rgba(145, 158, 171, 0.32)',
  [theme.breakpoints.up('sm')]: {
    maxHeight: '180px'
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '93px'
  }
}));

const FooterTextGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center'
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.footer,
  [theme.breakpoints.up('xs')]: {
    margin: theme.spacing(2, 0)
  },
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 0)
  }
}));

const RouterLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none'
}));

const RootGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%'
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const styles = {
  container: {
    '@media (max-width: 768px)': {
      marginTop: '10px'
    },
    '@media (min-width: 768px) and (max-width: 1534px)': {
      marginTop: '90px'
    },
    '@media (min-width: 1535px) and (max-width: 3260px)': {
      marginTop: '150px'
    }
  },
  studioimg: {
    '@media (min-width: 1235px) and (max-width: 3260px)': {
      width: '400px'
    }
  },
  box: {
    '@media (min-width: 768px) and (max-width: 1244px)': {
      width: '30%'
    }
  }
};

export default function Login() {
  const isSafari = useMemo(() => (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')), []);

  return (
    <>
      <div
        style={{
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: `repeat(2, 1fr)`,
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <div style={{ position: 'relative' }}>
          <img
            alt=""
            src={leftBackImg}
            style={{ objectFit: 'fill', height: !isSafari ? '100%' : 'inherit',  marginRight: '10px' }}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '40%',
              transform: `translate(-50%, -50%)`
            }}
          >
            <img src={neuroStudioWhite} alt="" height="400" width="400px" />
          </div>
          <div
            style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}
          >
            {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
            <Box fontSize="18px">Version : 3.0</Box>
          </div>
        </div>

        <div style={{margin:'auto'}}>
          {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}
          <BoxContainer display="flex" justifyContent="center" sx={{ p: 3 }}>
            <div style={{ padding: '50px 50px 0 0' }}>
              <Box
                display="flex"
                justifyContent="center"
                fontSize="27px"
                letterSpacing={1.5}
                fontWeight={900}
              >
                Inmed AI Studio Login
              </Box>
              <Box
                padding="10px"
                display="flex"
                justifyContent="center"
                fontSize="13px"
                color="gray"
              >
                Welcome! Enter your credentials below.
              </Box>
              <Box  >
              <LoginForm />
              </Box>
            </div>
            {/* <BoxContainer sx={{ pt: 1 }}>
              <Typography>
                <RouterLink to={{ pathname: '/forgot-password' }}> Forgot Password?</RouterLink>
              </Typography>
            </BoxContainer>
            <BoxContainer sx={{ pt: 2 }}>
              <Typography>
                By clicking on login, you agree to our
                <RouterLink to={{ pathname: '/legal/terms-and-conditions' }} target="_blank">
                  {' '}
                  terms and conditions
                </RouterLink>
                .
              </Typography>
            </BoxContainer>
            <BoxContainer sx={{ pt: 2 }}>
              <Typography>
                Don't have an Account ?
                <RouterLink to={{ pathname: '/register' }}> Register</RouterLink>.
              </Typography>
            </BoxContainer> */}
          </BoxContainer>
        </div>
      </div>
    </>
  );
}
