export const quickAccess = [
  `Terms and Conditions`,
  `Modifications to these terms and conditions`,
  `Access to the platforms`,
  `Who is InMed`,
  `Your representations and warranties`,
  `Termination`,
  `Prohibited Uses`,
  `Intellectual property rights and content`,
  `External links`,
  `No endorsements`,
  `Disclaimer`,
  `Children`,
  `Choice of law and jurisdiction`,
  `Personal information and privacy policy`,
  `Waiver`,
  `Entire agreement`,
  `Electronic documents`,
  `Assignment`,
  `Language`,
  `Contact / Notices`
];

export const prohibitedUses = [
  `any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to the Websites and Platforms or computers of any kind, and any unsolicited advertising, solicitation or promotional materials;`,
  `Misrepresent your identity or affiliation in any way;`,
  `Restrict or inhibit any person from using the Websites and`,
  `Platforms, disclose personal information obtained from the Websites and Platforms or collect information about users of the Websites and Platforms;`,
  `Reverse engineer, disassemble or decompile any section or technology on the Websites and Platforms, or attempt to do any of the foregoing;`,
  'Gain unauthorized access to the Websites and Platforms, to other',
  `users' accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to the Websites and Platforms;`,
  `Launch or use any automated system, including without limitation,`,
  `"robots," "spiders," or "offline readers," that access the Websites and Platforms in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;`,
  `Send or otherwise transmit to or through the Websites and Platforms`,
  `chain letters, unsolicited messages, so-called "spamming" or "phishing" messages, or messages marketing or advertising goods and services;`,
  `Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment;`,
  `Violate any applicable laws or regulations in any way;`,
  `Alter or modify any part of the content or services offered on or through the Websites and Platforms;`,
  `Allow any other person to use the Platforms with your registration or login information;`,
  `Breach or otherwise circumvent InMed's security or authentication measures; and`,
  `Assist or permit any persons in engaging in any of the activities described above.`
];

export const warranties = [
  `(i) you are at least eighteen (18) years of age, either a technician, doctor or _______`,
  `(ii) you have the legal ability and authority to enter into these Terms and Conditions with InMed, `,
  `(iii) the information you have provided to InMed in your registration is accurate and complete,`,
  `(iv) you will comply with any and all laws applicable to your use of the Websites and Platforms,`,
  `(v) you will not interfere with a third party's use and enjoyment of the Websites and Platforms,`,
  `(vi) you will not interfere with or disrupt InMed's or its vendors' security measures,`,
  `(vii) if any information you provide to InMed becomes inaccurate, incomplete or otherwise false or misleading, you will immediately notify InMed, `,
  `(viii) you acknowledge that access to the provided through the Platforms are not "insured services" under any provincial health plans,`,
  `(ix) you acknowledge that any fees paid by you or by any other persons on your behalf are not provided in exchange for any undertaking by InMed or its representatives that such healthcare services will be made available to you.`
];

export const endorsements = [
  `(a) violation of these Terms and Conditions; `,
  `(b) access to or use of the Platform and Websites; and `,
  `(c) provision of other disclosure to InMed of any other information or data and the use of same by InMed hereunder.`
];

export const electronicDocuments = [
  `(a) deemed for all purposes to be a "writing" or "in writing", and to comply with all statutory, contractual, and other legal requirements for a writing; and `,
  `(b) legally enforceable as a signed agreement.`
];
