import React from 'react';

import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { clearSnackbar } from './actions';

const SnackBar = () => {
  const { snackbarOpen, snackbarMessage, snackbarType, style } = useSelector(
    ({ snackbarReducer }) => snackbarReducer
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearSnackbar());
  };
  return (
    <Snackbar
      severity={snackbarType}
      autoHideDuration={5000}
      anchorOrigin={style}
      open={snackbarOpen}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{ backgroundColor: snackbarType === 'success' ? 'green' : 'red' }}
        message={snackbarMessage}
      />
    </Snackbar>
  );
};

export default SnackBar;
