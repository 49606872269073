import React, { useState } from 'react';
import { Button, Grid, Modal, Typography, Link, Box, LinearProgress } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const UploadProgress = (props) => {
  const { open, onClose, progress } = props;
  const [modalStyle] = useState(getModalStyle);
  const [gridItemStyle] = useState(getGridItemStyle);

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  }));

  const Classes = useStyles();

  function getModalStyle() {
    const top = 33;
    const left = 25;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${left}%, ${top}%)`
    };
  }

  function getGridItemStyle() {
    return {
      display: 'flex',
      justifyContent: 'center',
      mt: 2
    };
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={Classes.paper}>
        <Typography sx={{ textAlign: 'center' }}>
          Your files are being uploaded
        </Typography>
        <Box mt={3}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </div>
    </Modal>
  );
};

export default UploadProgress;
