import axios from 'axios';


export const fetchSites = () =>(

    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/sites`)
    .catch((error)=>(error))

)

