const initialState ={
    userId: '',
}


export const editUserReducer = (state = initialState, {type,payload}) => {
    switch (type) {
        case 'SET_USERID':
           return {userId:payload}; 
        default:
            return state;
    }
}