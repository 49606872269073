import { SNACKBAR_SHOW, SNACKBAR_CLEAR } from './actions';

const initialState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: 'success',
  duration: 3000,
  style: { vertical: 'top', horizontal: 'right' }
};
const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_SHOW:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload.message,
        snackbarType: action.payload.type,
        duration: action.payload.duration || state.duration,
        style: action.payload.style || state.style
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false
      };
    default:
      return state;
  }
};

export default snackbarReducer;
