
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import DeleteConfirmation from '../../../components/delete-confirmation';
import { iconStyle } from '../../../constants';
import { useDeleteEntity } from './hooks';

const DeleteEntity = (props) => {
  const { openModal, handleModal, handleDeleteEntity } = useDeleteEntity(props);
  return (
    <>
      <Tooltip title="Delete" arrow>
        <Delete style={iconStyle} onClick={handleModal} />
      </Tooltip>
      <DeleteConfirmation
        handleDelete={handleDeleteEntity}
        handleModal={handleModal}
        openModal={openModal}
        message="Do You want to Delete this Entity permanently ?"
      />
    </>
  );
};

export default DeleteEntity;

