/* eslint-disable no-unreachable */
// material
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { useFormik } from 'formik'; // <-- Import useFormik
import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { Link, useNavigate } from 'react-router-dom';
// layouts
// components

import neurostudio from '../../../assests/Images/INMED AI STUDIO final-01 1.png';
import leftBackImg from '../../../assests/Images/MicrosoftTeams-image (2).png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';

// ----------------------------------------------------------------------


const RouterLink = styled(Link)(() => ({
  textDecoration: 'none'
}));


const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const validationSchema = Yup.object().shape({
  entityName: Yup.string().required('Organization Name is required') // Username is required
});

export default function RegionRegister() {
  const navigate = useNavigate();
  const [defaultCountry, setDefaultCountry] = useState(
    localStorage.getItem('selectedCountry') || 'US'
  );
  const formik = useFormik({
    initialValues: {
      entityName: '',
      selectedCountry: defaultCountry
    },
    // eslint-disable-next-line object-shorthand
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { entityName, selectedCountry } = values;
      if (selectedCountry === 'India') {
        // navigate(`/register?organizationName=${entityName}`);
        window.location = `${process.env.REACT_APP_VOLUMETRY_APP_URL}/register?organizationName=${entityName}`;
      } else if (selectedCountry === 'US') {
        window.location = `${process.env.REACT_APP_US_APP_URL}/register?organizationName=${entityName}`;
        // navigate(`/register?organizationName=${entityName}`);
      }
      localStorage.setItem('selectedCountry', defaultCountry);
    }
  });
  useEffect(() => {
    localStorage.setItem('selectedCountry', defaultCountry);
  }, [defaultCountry]);

  const handleCountryChange = (event) => {
    setDefaultCountry(event.target.value);
    formik.setFieldValue('selectedCountry', event.target.value);
  };

  const isSafari = useMemo(() => (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')), []);

  return (
    <>
      <div
        style={{
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: `repeat(2, 1fr)`,
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <div style={{ position: 'relative' }}>
          <img
            alt=""
            src={leftBackImg}
            style={{ objectFit: 'fill', height: !isSafari ? '100%' : 'inherit',  marginRight: '10px' }}

          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '40%',
              transform: `translate(-50%, -50%)`
            }}
          >
            <img src={neuroStudioWhite} alt="" height="400" width="400px" />
          </div>
          <div
            style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}
          >
            {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
            <Box fontSize="18px">Version : 3.0</Box>
          </div>
        </div>

        <div style={{ margin:'auto' }}>
          {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}
          <BoxContainer display="flex" justifyContent="center" sx={{ pb: 3, pr: '150px' }} mt={7}>
            <Box
              display="flex"
              justifyContent="center"
              fontSize="27px"
              marginLeft='40px'
              letterSpacing={1.5}
              fontWeight={900}
            >
              Inmed AI Studio Register
            </Box>
          </BoxContainer>

          <form style={{marginRight:'80px'}} onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              autoComplete="entityName"
              type="text"
              label="Organization Name"
              name="entityName"
              value={formik.values.entityName}
              onChange={formik.handleChange}
              error={formik.touched.entityName && Boolean(formik.errors.entityName)}
              helperText={formik.touched.entityName && formik.errors.entityName}
            />
            <Stack direction="row" alignItems="center" justifyContent="left" sx={{ my: 2 }}>
              <FormControlLabel
                control={<Radio onChange={handleCountryChange} value="US" />}
                checked={formik.values.selectedCountry === 'US'}
                label="US"
              />
              <FormControlLabel
                control={<Radio onChange={handleCountryChange} value="India" />}
                checked={formik.values.selectedCountry === 'India'}
                label="India"
              />
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Next
            </LoadingButton>
          </form>

          <BoxContainer sx={{ pt: 2 }}>
              <Typography>
              Already have an account??
                <RouterLink to={{ pathname: '/' }}> Login</RouterLink>.
              </Typography>
            </BoxContainer>
        </div>
      </div>
    </>
  );
}
