import { isEmpty } from 'lodash';

const initialState = {
  isAuthenticated: false,
  token: '',
  passcode: '',
  user: {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    sites: [],
    hasBleedSubscription: false
  }
};

export const loggedInUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_LOGGED_IN_USER':
      return {
        isAuthenticated: !isEmpty(payload),
        token: payload.token,
        passcode: payload.passcode,
        user: payload.data
      };
    case 'SET_LOGOUT_STATUS':
      return initialState;
    default:
      return state;
  }
};
