import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography
} from '@material-ui/core';
import { Form, FormikProvider } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import logo from '../../../assests/Images/logo.jpg';
import { useResetPassword } from './hooks';
import leftBackImg from '../../../assests/Images/MicrosoftTeams-image (2).png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';

const RouterLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none'
}));

const ResetPassword = () => {
  const {
    formik,
    classes,
    responseState,
    vertical,
    horizontal,
    open,
    handleClose,
    showPassword,
    handleShowPassword,
    showConfirmPassword,
    handleShowConfirmPassword
  } = useResetPassword();

  const { handleSubmit, getFieldProps, errors, touched } = formik;
  return (
    <div
      style={{
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: `repeat(2, 1fr)`,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          alt=""
          src={leftBackImg}
          style={{ objectFit: 'fill', height: '100%', marginRight: '10px' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: `translate(-50%, -50%)`
          }}
        >
          <img src={neuroStudioWhite} alt="" height="400" width="400px" />
        </div>
        <div style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}>
          {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
          <Box fontSize="18px">Version : 3.0</Box>
        </div>
      </div>

      <div style={{ margin: 'auto', width: '55%' }}>
        {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}
        {/* <BoxContainer
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3, pr: '150px' }}
          mt={7}
        > */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="27px"
          p={3}
          letterSpacing={0.9}
          fontWeight={900}
        >
          Inmed AI Studio Reset Password
        </Box>
        {/* </BoxContainer> */}

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={classes.form}>
            <Snackbar
              severity={responseState.error ? 'red' : 'green'}
              autoHideDuration={5000}
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              key={vertical + horizontal}
            >
              <SnackbarContent
                style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
                message={responseState.msg}
              />
            </Snackbar>

            <div className={classes.mainHeading}>
              {/* <img src={logo} alt="Neuro-Shield" width="300" /> */}
            </div>
            <div className={classes.formBody}>
              {/* <h2 className={classes.H2}>Reset Password</h2> */}
              <Typography className={classes.para}>Please enter your new password</Typography>
              <TextField
                className={classes.input}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                className={classes.input}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                {...getFieldProps('confirmPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowConfirmPassword} edge="end">
                        <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <br />
              <LoadingButton
                fullWidth
                size="large"
                className={classes.btn}
                variant="contained"
                color="primary"
                type="submit"
              >
                Reset Password
              </LoadingButton>
              <Typography className={classes.back}>
                Back to &nbsp;
                <Link to="/login">Login</Link>
              </Typography>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );

  // return (
  //   <FormikProvider value={formik}>
  //     <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={classes.form}>
  //       <Snackbar
  //         severity={responseState.error ? 'red' : 'green'}
  //         autoHideDuration={5000}
  //         anchorOrigin={{ vertical, horizontal }}
  //         open={open}
  //         onClose={handleClose}
  //         key={vertical + horizontal}
  //       >
  //         <SnackbarContent
  //           style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
  //           message={responseState.msg}
  //         />
  //       </Snackbar>

  //       <div className={classes.mainHeading}>
  //         <img src={logo} alt="Neuro-Shield" width="300" />
  //       </div>
  //       <div className={classes.formBody}>
  //         <h2 className={classes.H2}>Reset Password</h2>
  //         <Typography className={classes.para}>Please enter your new password</Typography>
  //         <TextField
  //           className={classes.input}
  //           fullWidth
  //           type={showPassword ? 'text' : 'password'}
  //           label="New Password"
  //           {...getFieldProps('password')}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <IconButton onClick={handleShowPassword} edge="end">
  //                   <Icon icon={showPassword ? eyeFill : eyeOffFill} />
  //                 </IconButton>
  //               </InputAdornment>
  //             )
  //           }}
  //           error={Boolean(touched.password && errors.password)}
  //           helperText={touched.password && errors.password}
  //         />

  //         <TextField
  //           className={classes.input}
  //           fullWidth
  //           type={showConfirmPassword ? 'text' : 'password'}
  //           label="Confirm Password"
  //           {...getFieldProps('confirmPassword')}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <IconButton onClick={handleShowConfirmPassword} edge="end">
  //                   <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
  //                 </IconButton>
  //               </InputAdornment>
  //             )
  //           }}
  //           error={Boolean(touched.confirmPassword && errors.confirmPassword)}
  //           helperText={touched.confirmPassword && errors.confirmPassword}
  //         />
  //         <br />
  //         <Button className={classes.btn} variant="contained" color="primary" type="submit">
  //           Reset Password
  //         </Button>
  //         <Typography className={classes.back}>
  //           Back to &nbsp;
  //           <Link to="/login">Login</Link>
  //         </Typography>
  //       </div>
  //     </Form>
  //   </FormikProvider>
  // );
};

export default ResetPassword;
