import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import logo from '../assests/Images/neuroshield-logo.png';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src={logo} sx={{ width: 160, height: 100, ...sx }} />;
}
