import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useEditEntity = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const { data: { data: { data: selectedEntity = {} } = {} } = {}} = useQuery(
    ['entityDetail', id],
    () => api().get(apiEndpoints.singleEntity(id)),

    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const { mutate: handleEditEntity } = useMutation(
    (payload) => api().patch(apiEndpoints.singleEntity(id), { entityName: payload.name, entityDescription: payload.description }),
    {
      onSuccess: () => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: 'Site Updated Successfully'
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error?.message || 'Something went wrong, please try after sometime'
          })
        );
      }
    }
  );

  return { handleEditEntity, selectedEntity };
};
