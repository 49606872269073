import axios from 'axios';


export const fetchSingleEntities = (entityId) => (

  axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/entities/${entityId}`)
    .then((response) => {
      console.log('Response:of the single entities', response.data);
      return response.data; // Return the data if needed for further processing
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error; // Rethrow the error to be handled at the caller's end if needed
    })

)
