import { Box } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import './style.css'

function InputPhone  (props)  {
  const { name, label, helperText, error, value, onPhoneChange, ...rest } = props;
  return (
    <Box pr={1} pl={1}>
      <Box pr={1}>
        {label && <>{label} {props.req && <span>*</span>}</>}
      </Box>
      <Box>
        <PhoneInput
          name={name}
          placeholder=''
          country='in'
          inputStyle={{
            borderColor: (helperText && error) && "red",cursor:'not-allowed'
          }}
          value={value}
          onChange={onPhoneChange}
          {...rest}
        />
        {helperText && error && <p style={{ color: 'red' }}>{helperText}</p>}
      </Box>
    </Box>
  );
};


export default InputPhone
