export const TABLE_HEAD = [
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'sites', label: 'Sites', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' }
];

export const userTitle = {
  add: 'Add User',
  edit: 'Edit User',
  details: 'User Report'
};
