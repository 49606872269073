import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useMutation, useQuery } from 'react-query';
import { LoadingButton } from '@material-ui/lab';
import { useLogin } from './hooks';

// ----------------------------------------------------------------------
const RouterLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none'
}))

export default function LoginForm() {
  const {
    formik,
    vertical,
    horizontal,
    open,
    handleClose,
    handleShowPassword,
    responseState,
    showPassword,
    counter,
    usernameParam
  } = useLogin();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
        </Snackbar>

        <Stack margin='auto' width={counter?"64%":null} mb={3} spacing={3}>
          <TextField
            // disabled={!!usernameParam}
            fullWidth
            autoComplete="username"
            type="text"
            label="Enter Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
          
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Enter Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          
        
        {!!counter && (
          <Stack>
            <Typography  variant="body2" align="center" color="error">
              You have made {counter} unsuccessful attempt(s). Your account will be locked after 15
              unsuccessful attempts.
            </Typography>
          </Stack>
        )}
       


          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />
          </Stack>
       

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
        <Stack>
        <Box sx={{ pt: 1 }}>
              <Typography>
                <RouterLink to={{ pathname: '/forgot-password' }}> Forgot Password?</RouterLink>
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography>
                By clicking on login, you agree to our
                <RouterLink to={{ pathname: '/legal/terms-and-conditions' }} target="_blank">
                  {' '}
                  terms and conditions
                </RouterLink>
                .
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography>
                Don't have an Account ?
                <RouterLink to={{ pathname: '/register' }}> Register</RouterLink>.
              </Typography>
            </Box>
            </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
