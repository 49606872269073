import rectangle from '@iconify/icons-ic/baseline-rectangle';
import { Icon } from '@iconify/react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

const HowToRead = () => {
  const headingStyle = {
    fontSize: '1.25rem',
    fontWeight: '500',
    // lineHeight: '1.5',
    textAlign: 'center'
  };

  const iconStyle = {
    stroke: '#000',
    strokeWidth: '1px'
  };

  const itemStyle = {
    '& li': {
      padding: '0 10px',
      '& p': {
        // fontSize: '1rem',
        color: '#212B36'
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <List sx={itemStyle}>
        <Typography sx={headingStyle}>
          <u>How To Read The Report</u>
        </Typography>
        <br />
        <ListItem>
          <ListItemText secondary="The reference ranges are provided on the right column." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="1. The references for structures will change according to age. Structural volume loss will be provided according to the age reference values and icv%." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="2. The structural volumes are given along with the percentage of volume they consume in the intracranial volume. Each structure’s volumes are matched with reference value as well as icv %. If the icv % is normal, the volumes are said to be within limits." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="Ex: hc value is 2.19 ml but the icv% is 0.25, then the hippocampal volume is within range according to icv." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="3. Atrophy percentage of structures who have measurable volume loss are provided in output analysis." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="4. Volume loss for structures is provided in ml as well as percentage value in output analysis." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="5. The asymmetry index explains the lateralization of atrophy or the side which might be affected. A negative value shows left sided asymmetry whereas a positive value shows right sided asymmetry. The reference value is provided." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="6. We advise patients under 30 to use their judgment in interpreting the volumes and correlate with other clinical findings." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="7. *&nbsp; While the volumes are highly accurate, reference range may not be as robust for under 30 as it is for over 30." />
        </ListItem>

        <br />

        <ListItem>
          <ListItemText secondary="#&nbsp;&nbsp;&nbsp; Age related expected atrophy is calculated at the rate of 5% atrophy per decade after age of 40 years." />
        </ListItem>
        <ListItem>
          <ListItemText secondary="**&nbsp;&nbsp; The Asymmetry Index is defined as the percentage difference between left and right volumes divided by their mean" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Icon icon={rectangle} style={{ ...iconStyle, color: 'red' }} />
          </ListItemIcon>
          <ListItemText secondary="Denotes that the structure has measurable atrophy" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon icon={rectangle} style={{ ...iconStyle, color: '#002060' }} />
          </ListItemIcon>
          <ListItemText secondary="Denotes that the volume of the structure lies on the 25th percentile" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon icon={rectangle} style={{ ...iconStyle, color: '#000' }} />
          </ListItemIcon>
          <ListItemText secondary="Denotes that the volume of the structure is within range" />
        </ListItem>
      </List>
    </div>
  );
};

export default HowToRead;
