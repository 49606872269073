import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useQuery } from 'react-query';
import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

const formField = {
  username: '',
  password: '',
  confirmPassword: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  country_code:'',
  sites: [],
  role: '',
  secondary_email_2:'',
  secondary_email_1:"",
  isWhatsAppOptIn:false
};

export const useUserForm = ({ fieldValues, isEdit, isDetail, handleUserForm, handleSuccess }) => {
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();
  const [isFormModified, setIsFormModified] = useState(false);
  const [resetValue, setResetValue] = useState(fieldValues || formField);

  useEffect(() => {
    if (handleSuccess && !isEdit) {
      setFormvalues(formField);
    }
  }, [handleSuccess, isEdit]);

  const { data: { data: { data: sites = [] } = {} } = {} } = useQuery(
    ['sites'],
    () => api().get(apiEndpoints.sites),
    {
      onError: (res) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: res.response.data.msg
          })
        );
      },
      onSuccess: (res) => {
        if (res.message === 'Network Error') {
          dispatch(
            showSnackbar({
              type: 'error',
              message: res.message
            })
          );
        }
      }
    }
  );

  const { data: { data: { data: roles = [] } = {} } = {} } = useQuery(
    ['roles'],
    () => api().get(apiEndpoints.roles),
    {
      onError: (res) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: res.response.data.msg
          })
        );
      },
      onSuccess: (res) => {
        if (res.message === 'Network Error') {
          dispatch(
            showSnackbar({
              type: 'error',
              message: res.message
            })
          );
        }
      }
    }
  );

  const handleFormSubmit = async (values) => {
    try {
      const res = await handleUserForm(values);
      setIsFormModified(false);
      setResetValue(values);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateSites = useCallback(() => {
    const tempSites = sites.filter((site) => fieldValues?.sites?.includes(site._id));
    setFormvalues({ ...fieldValues, sites: tempSites });
  }, [fieldValues, sites]);

  useEffect(() => {
    if (isEdit || isDetail) {
      updateSites();
    } else {
      setFormvalues(fieldValues || formField);
    }
  }, [fieldValues, isEdit, isDetail, updateSites]);

  const handleFormUpdate = (event) => {
    const caret = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    const { name, value } = event.target;

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value
    }));
    // if (name === 'secondary_email_1' || name === 'secondary_email_2') {
    //   setFormvalues((prevValues) => ({
    //     ...prevValues,
    //     secondary_emails: [
    //       prevValues.secondary_email_1,
    //       prevValues.secondary_email_2,
    //     ].filter(Boolean), // Remove undefined or empty strings
    //   }));
    setIsFormModified(true);
  // };
}

  const handleFormArrayUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value
    }));
    setIsFormModified(true);
  };

  const resetForm = () => {
    const tempSites = sites.filter((site) => fieldValues?.sites?.includes(site._id));
    if (isEdit) setFormvalues({ ...resetValue, sites: tempSites });
    else setFormvalues(formField);
    setIsFormModified(false);
  };

  return {
    sites,
    roles,
    formValues,
    handleFormUpdate,
    handleFormArrayUpdate,
    isFormModified,
    setIsFormModified,
    setFormvalues,
    formField,
    resetForm,
    handleFormSubmit
  };
};
