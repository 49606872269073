import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import DeleteConfirmation from '../../../components/delete-confirmation';
import { iconStyle } from '../../../constants';
import { useDeleteSite } from './hooks';

const DeleteSite = (props) => {
  const { openModal, handleModal, handleDeleteSite } = useDeleteSite(props);
  return (
    <>
      <Tooltip title="Delete" arrow>
        <Delete style={iconStyle} onClick={handleModal} />
      </Tooltip>
      <DeleteConfirmation
        handleDelete={handleDeleteSite}
        handleModal={handleModal}
        openModal={openModal}
        message="Do you want to Delete this site permanently ?"
      />
    </>
  );
};

export default DeleteSite;
