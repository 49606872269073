import React from 'react';

import UserForm from '../form';
import { useUserDetails } from './hooks';

const EditUser = () => {
  const { selectedUser } = useUserDetails();
  return <UserForm fieldValues={selectedUser} isDetail />;
};

export default EditUser;
