import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';

export const useAddUser = () => {
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const { mutate: handleAddUser,isSuccess:handleSuccess } = useMutation(
    (payload) => {
      // logic
      payload.sites = payload.sites.map((site) => site._id);
      return api().post(apiEndpoints.getUsers, payload);
    },
    {
      onSuccess: (response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.msg
          })
        );
      },
      onError: (error) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error.response.data.msg
          })
        );
      }
    }
  );

  return { handleAddUser,handleSuccess };
};
