/* eslint-disable no-nested-ternary */
import { useCallback, useState } from 'react';

import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash/debounce';
import { fetchSites } from '../../../api/uploadStudy/fetchSites';
import { showSnackbar } from '../../../components/snackbar/actions';
import { useApi } from '../../../config/api';
import { applySortFilter } from '../../../utils/helpers';
import { useStyles } from './styles';

export const useUserList = () => {
  const [selected, setSelected] = useState([]);

  const [searchedSite] = useState('');
  const [filterUserName, setFilterUserName] = useState('');

  const { api, apiEndpoints } = useApi();

  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    username: '',
    siteId: '',
    sort: { username: 1 },
    order: 'asc',
    orderBy: 'username'
  });

  const Classes = useStyles();

  const dispatch = useDispatch();

  const handleFilterByUserName = (e) => {
    const username = e.target.value;
    // Reset the offset to 0 when a new search is performed
    setParams((prev) => ({ ...prev, username, offset: 0 }));
  };

  const {
    data: { data: { data: users = [], count: total = 0 } = {} } = {},
    isLoading: usersLoading,
    refetch: refetchUsers
  } = useQuery(
    ['users', params],
    () =>
      api().get(apiEndpoints.getUsers, {
        params: { ...params, siteId: params.siteId._id || '' }
      }),
    {
      onSuccess: () => {},
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const { data: { data: { data: sites = [] } = {} } = {} } = useQuery(
    ['sites'],
    () => api().get(apiEndpoints.sites),
    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  function handleSiteName(fetchedSites) {
    const siteNames = [];

    for (let i = 0; i < fetchedSites.length; i += 1) {
      for (let j = 0; j < sites.length; j += 1) {
        if (fetchedSites[i] === sites[j]._id) {
          siteNames.push(`${sites[j].name}${fetchedSites.length - 1 === i ? '.' : ','} `);
        }
      }
    }
    return siteNames;
  }

  const handleRequestSort = (event, property) => {
    if (property === 'action' || property === 'sites') return;
    setParams((prev) => {
      const order = !prev.sort[property] ? 'desc' : prev.sort[property] === 1 ? 'desc' : 'asc';
      return {
        ...prev,
        order,
        orderBy: property,
        sort: { [property]: order === 'asc' ? 1 : -1 }
      };
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (patientId) => {
    const selectedIndex = selected.indexOf(patientId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, patientId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * params.limit;
    setParams((prev) => ({ ...prev, offset: newOffset }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({ ...prev, limit: parseInt(event.target.value, 10), offset: 0 }));
  };

  const handleSiteId = (e) => {
    setParams((prev) => ({ ...prev, siteId: e.target.value, limit: 5, offset: 0 }));
  };

  const { user } = useSelector((state) => state.loggedInUser);

  return {
    user,
    Classes,
    applySortFilter,
    fetchSites,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
    handleSiteName,
    handleSelectAllClick,
    handleRequestSort,
    searchedSite,
    selected,
    sites,
    users,
    total,
    handleFilterByUserName,
    filterUserName,
    params,
    handleSiteId,
    usersLoading,
    refetchUsers
  };
};
