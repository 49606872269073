// import all reducers here
import { combineReducers } from 'redux';
import { loggedInUserReducer } from './authReducer';
import snackbarReducer from '../../components/snackbar/reducer';
import { editUserReducer } from './editUserReducer';
import { worklistTableDataReducer } from './worklistTableDataReducer';

const allReducers = combineReducers({
  loggedInUser: loggedInUserReducer,
  editUser: editUserReducer,
  worklistTableData: worklistTableDataReducer,
  snackbarReducer
});

export default allReducers;
