import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { capitalize } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Assignment, Edit } from '@material-ui/icons';
import ListToolBar from '../../../components/list-toolbar';
import EntityListToolbar from '../EntityListToolbar'
import Scrollbar from '../../../components/Scrollbar';
import TableHeader from '../../../components/table-header';
import { useEntityList } from './hooks';
import Page from '../../../components/Page';
import DeleteEntity from '../deleteEntity';
import SearchNotFound from '../../../components/SearchNotFound';
import { iconStyle } from './styles';
import SearchFilter from '../../../components/search-filter';


const TABLE_HEAD = [
  // { id: 'id', label: 'Entity ID', alignRight: false },
  { id: 'name', label: 'Entity Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];


const EntityList = () => {
  const {
    Classes,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByEntityName,
    handleClick,
    handleRequestSort,
    handleSelectAllClick,
    entities,
    searchedEntity,
    selected,
    params,
    total,
    entitiesLoading,
    refreshPage
  } = useEntityList()

  return (
    <Page title="Entities | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Entities
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/entities/create-entity"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Entity
          </Button>
        </Stack>
        <Card>

          <ListToolBar
            // numSelected={selected.length}
            // filterEntityName={searchedEntity}
            // onFilterEntityName={handleFilterByEntityName}
        >
          <SearchFilter
              searchedValue={params.entityname}
              handleSearch={handleFilterByEntityName}
              placeholder="Enitity Name"
              name='entityname'
          />
        </ListToolBar>


          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={params.order}
                  orderBy={params.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={entities.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  showSelectAll={false}
                />
                <TableBody>
                  {/* {console.log("entities",entities)} */}
                  {entities
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id: patientId, description, name } = row;
                      const isItemSelected = selected.indexOf(patientId) !== -1;

                      return (
                        <TableRow
                          hover
                          key={patientId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => handleClick(patientId)}
                            />
                          </TableCell> */}
                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={patientId} >{name[0]}</Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {patientId || "NA"}
                              </Typography>
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left">{capitalize(name) || "NA"}</TableCell>
                          <TableCell align="left">{description || "NA"}</TableCell>

                          <TableCell align="left">
                            <RouterLink to={{ pathname: `/dashboard/entities/details/${patientId}` }}>
                              <Tooltip title="Details" arrow ><Assignment style={iconStyle} /></Tooltip>
                            </RouterLink>
                            <RouterLink to={{ pathname: `/dashboard/entitieslist/edit-entity/${patientId}` }}>
                              <Tooltip title="Edit" arrow><Edit style={iconStyle} /></Tooltip>
                            </RouterLink>
                            <DeleteEntity callback={refreshPage} classes={Classes} id={patientId} />
                          </TableCell>

                          {/* <Modal
                              open={modalOpen}
                              onClose={handleModalClose}
                            >
                            <div style={modalStyle} className={Classes.paper}>
                            <Box my={3}>
                              <p>Do You want to Delete this site permanently</p>
                            </Box>
                            <Button variant='contained' onClick={(()=>{handleDeleteEntity(patientId);setModalOpen(false);})} style={{marginRight:'10px'}}>Delete</Button>
                            <Button variant='contained' onClick={(()=>{setModalOpen(false);})}> Cancel </Button>
                            </div>
                          </Modal> */}

                        </TableRow>

                      );
                    })}

                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {entities.length<=0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchedEntity} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={params.limit}
            page={Math.floor(params.offset / params.limit)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )

}
export default EntityList;
