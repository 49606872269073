/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useApi } from '../../../config/api';
import { showSnackbar } from '../../../components/snackbar/actions';

const formField = {
  name: '',
  description: ''
};

export const useEntityForm = ({ fieldValues, handleEntityForm, isEdit, handleSuccess }) => {
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();
  const [isFormModified, setIsFormModified] = useState(false);
  const [resetValue, setResetValue] = useState(fieldValues || formField);

  useEffect(() => {
    if (handleSuccess && !isEdit) {
      setFormvalues(formField);
    }
  }, [handleSuccess, isEdit]);

  useEffect(() => {
    if (fieldValues) {
      fieldValues.name = fieldValues?.name || '';
      fieldValues.description = fieldValues?.description || '';
      setFormvalues(fieldValues);
      setResetValue(fieldValues)
    }
  }, [fieldValues]);

  const { data: { data: { data: entities = [] } = {} } = {} } = useQuery(
    ['site-form-entities'],
    () => api().get(apiEndpoints.entities),
    {
      onError: (res) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: res?.response?.data?.msg
          })
        );
      },
      onSuccess: (res) => {
        if (res.message === 'Network Error') {
          dispatch(
            showSnackbar({
              type: 'error',
              message: res?.message
            })
          );
        }
      }
    }
  );
  const handleFormSubmit = async (values) => {
    try {
      await handleEntityForm(values);
      setIsFormModified(false);
      setResetValue(values);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFormUpdate = (event) => {
    const { name, value } = event.target;

    const caret = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value
    }));
    setIsFormModified(true);
  };

  const handleFormArrayUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value
    }));
    setIsFormModified(true);
  };

  const resetForm = () => {
    if (isEdit) setFormvalues(resetValue);
    else setFormvalues(formField);
  };

  return {
    entities,
    formValues,
    handleFormUpdate,
    handleFormArrayUpdate,
    setFormvalues,
    formField,
    resetForm,
    handleFormSubmit,
    fieldValues,
    isFormModified,
    setIsFormModified
  };
};
