
export const setUserId = (id) => (
    {
        type:'SET_USERID',
        payload:id
    }
)

export const setLoggedInUser = (user) => ({
    type: 'SET_LOGGED_IN_USER',
    payload: user

})

export const setLogoutStatus = () => ({
    type: 'SET_LOGOUT_STATUS',
    payload: null,
})